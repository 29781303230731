/* eslint-disable no-nested-ternary */
import React from 'react'
import { Box, BoxProps, styled } from '@mui/material'
import { CategorySummaryData } from 'model'
import PercentBar from './PercentBar'

const PercentContainer = styled(Box)<BoxProps>(() => ({
  width: '100%',
  display: 'flex',
  position: 'relative'
}))

interface NumberBoxProps extends BoxProps {
  percent: number
}

const NumberBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'percent'
})<NumberBoxProps>(({ percent }) => ({
  fontSize: '12px',
  fontWeight: 700,
  color: 'rgb(116, 119, 121)',
  background: 'transparent',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: `${percent * 66.666667 + 25}%`,
  marginLeft: percent === 1 ? '-15px' : percent === 0 ? '-5px' : '-10px'
}))

interface IProps {
  responseData: CategorySummaryData[]
}

const Percentages = ({ responseData }: IProps) => {
  return (
    <Box>
      <PercentContainer>
        <NumberBox percent={0}>0%</NumberBox>
        <NumberBox percent={0.25}>25%</NumberBox>
        <NumberBox percent={0.5}>50%</NumberBox>
        <NumberBox percent={0.75}>75%</NumberBox>
        <NumberBox percent={1}>100%</NumberBox>
      </PercentContainer>
      <PercentBar
        dataLength={responseData.length}
        percent={0.25 * 66.66667 + 25}
      />
      <PercentBar
        dataLength={responseData.length}
        percent={0.5 * 66.66667 + 25}
      />
      <PercentBar
        dataLength={responseData.length}
        percent={0.75 * 66.66667 + 25}
      />
    </Box>
  )
}
export default Percentages

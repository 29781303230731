/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext } from 'react'
import { MicroServiceContext } from 'context/MicroService'
import { CategorySummaryData, DepartmentSummaryData, SurveyCount } from 'model'
import useEmployeeSurvey from 'hooks/useEmployeeSurvey'
import { Box, CircularProgress } from '@mui/material'
import {
  INITIAL_TOP_THREE_STRENGTHS,
  INITIAL_SURVEY_COUNT,
  INITIAL_DEPARTMENT_REPRESENTATION,
  DEMO_ATTRACTING_SOURCING_DATA,
  DEMO_DEFINING_TRACKING_DATA,
  DEMO_INCLUSION_BELONGING_DATA,
  DEMO_INTERVIEWING_ENGAGING_DATA,
  DEMO_ONBOARDING_ADVANCING_DATA,
  DEMO_REPRESENTATION_SUMMARY,
  DEMO_RESPONSE_RATE_DATA,
  DEMO_SURVEY_COUNT,
  CONSTELLATION_SURVEY_COUNT,
  CONSTELLATION_RESPONSE_RATE_DATA,
  CONSTELLATION_DEFINING_TRACKING_DATA,
  CONSTELLATION_INCLUSION_BELONGING_DATA,
  CONSTELLATION_ATTRACTING_SOURCING_DATA,
  CONSTELLATION_ONBOARDING_ADVANCING_DATA
} from './constants'

type SurveySummaryData = {
  startDate: Date
  endDate: Date
  surveyCountSummary: SurveyCount | null
  responseRateData: DepartmentSummaryData[] | null
  representationSummary: any | null
  definingTrackingData: CategorySummaryData[] | null
  inclusionBelongingData: any | null
  equitableData: any
  onboardingAdvancingData: CategorySummaryData[] | null
  isLoading: boolean
  showDigDeeper: boolean
  useDefaultGraph: boolean
}

export const SurveySummaryContext = createContext<SurveySummaryData>({
  startDate: new Date(),
  endDate: new Date(),
  isLoading: true,
  showDigDeeper: false,
  surveyCountSummary: INITIAL_SURVEY_COUNT,
  responseRateData: INITIAL_DEPARTMENT_REPRESENTATION,
  representationSummary: [],
  definingTrackingData: INITIAL_TOP_THREE_STRENGTHS,
  inclusionBelongingData: {},
  equitableData: {},
  onboardingAdvancingData: INITIAL_TOP_THREE_STRENGTHS,
  useDefaultGraph: true
})

export const SurveySummaryProvider = ({ children }: any) => {
  const { employerSurveySummary, isLoading } = useEmployeeSurvey()
  const { currentAccount, isFlagEnabled } = useContext(MicroServiceContext)

  if (isLoading || !currentAccount) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress
          size={60}
          sx={{ margin: 'auto', marginTop: '32px' }}
        />
      </Box>
    )
  }

  const isDemo: boolean = isFlagEnabled('employee-survey-demo-data')

  const isConstellation: boolean = isFlagEnabled(
    'enable_constellation_survey_summary'
  )

  if (isDemo) {
    return (
      <SurveySummaryContext.Provider
        value={{
          isLoading: false,
          showDigDeeper: false,
          useDefaultGraph: true,
          startDate: new Date('2022-12-12T00:00:00Z'),
          endDate: new Date('2022-12-19T00:00:00Z'),
          surveyCountSummary: DEMO_SURVEY_COUNT,
          responseRateData: DEMO_RESPONSE_RATE_DATA,
          representationSummary: DEMO_REPRESENTATION_SUMMARY,
          definingTrackingData: DEMO_DEFINING_TRACKING_DATA,
          inclusionBelongingData: DEMO_INCLUSION_BELONGING_DATA,
          equitableData: {
            attracting_sourcing: DEMO_ATTRACTING_SOURCING_DATA,
            interviewing_engaging: DEMO_INTERVIEWING_ENGAGING_DATA
          },
          onboardingAdvancingData: DEMO_ONBOARDING_ADVANCING_DATA
        }}
      >
        {children}
      </SurveySummaryContext.Provider>
    )
  }

  if (isConstellation) {
    return (
      <SurveySummaryContext.Provider
        value={{
          isLoading: false,
          showDigDeeper: false,
          useDefaultGraph: false,
          startDate: new Date('2023-04-25T00:00:00Z'),
          endDate: new Date('2023-05-09T00:00:00Z'),
          surveyCountSummary: CONSTELLATION_SURVEY_COUNT,
          responseRateData: CONSTELLATION_RESPONSE_RATE_DATA,
          representationSummary: null,
          definingTrackingData: CONSTELLATION_DEFINING_TRACKING_DATA,
          inclusionBelongingData: CONSTELLATION_INCLUSION_BELONGING_DATA,
          equitableData: {
            attracting_sourcing: CONSTELLATION_ATTRACTING_SOURCING_DATA,
            interviewing_engaging: null
          },
          onboardingAdvancingData: CONSTELLATION_ONBOARDING_ADVANCING_DATA
        }}
      >
        {children}
      </SurveySummaryContext.Provider>
    )
  }

  return (
    <SurveySummaryContext.Provider
      value={{
        isLoading,
        showDigDeeper: false,
        useDefaultGraph: true,
        startDate: employerSurveySummary?.startAt,
        endDate: employerSurveySummary?.endAt,
        surveyCountSummary:
          employerSurveySummary?.surveyCountSummary &&
          employerSurveySummary?.surveyCountSummary.survey_data
            ? employerSurveySummary?.surveyCountSummary.survey_data
            : null,
        responseRateData:
          employerSurveySummary?.departmentSummary &&
          employerSurveySummary?.departmentSummary.survey_data
            ? employerSurveySummary?.departmentSummary.survey_data
            : null,
        representationSummary:
          employerSurveySummary?.representationSummary || null,
        definingTrackingData:
          employerSurveySummary?.categorySummary.survey_data &&
          employerSurveySummary?.categorySummary.survey_data
            .defining_tracking &&
          employerSurveySummary?.categorySummary.survey_data.defining_tracking
            ?.top_strengths
            ? employerSurveySummary?.categorySummary.survey_data
                .defining_tracking?.top_strengths
            : null,
        inclusionBelongingData:
          employerSurveySummary?.categorySummary.survey_data &&
          employerSurveySummary?.categorySummary.survey_data.inclusion_belonging
            ? employerSurveySummary?.categorySummary.survey_data
                .inclusion_belonging
            : null,
        equitableData: {
          attracting_sourcing:
            employerSurveySummary?.categorySummary.survey_data &&
            employerSurveySummary?.categorySummary.survey_data
              .attracting_sourcing
              ? employerSurveySummary?.categorySummary.survey_data
                  .attracting_sourcing
              : null,
          interviewing_engaging:
            employerSurveySummary?.categorySummary.survey_data &&
            employerSurveySummary?.categorySummary.survey_data
              .interviewing_engaging
              ? employerSurveySummary?.categorySummary.survey_data
                  .interviewing_engaging
              : null
        },
        onboardingAdvancingData:
          employerSurveySummary?.categorySummary.survey_data &&
          employerSurveySummary?.categorySummary.survey_data
            .onboarding_advancing &&
          employerSurveySummary?.categorySummary.survey_data
            .onboarding_advancing.top_strengths
            ? employerSurveySummary?.categorySummary.survey_data
                .onboarding_advancing.top_strengths
            : null
      }}
    >
      {children}
    </SurveySummaryContext.Provider>
  )
}

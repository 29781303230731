/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { MicroServices } from 'model/microServices'

export const MicroServiceContext = React.createContext<MicroServices>({
  displayNotification: () => {},
  currentAccount: {
    id: '',
    employer: { name: '', code: '' },
    user: { id: '', name: '', primaryEmail: '' }
  },
  maestroClient: undefined,
  children: undefined,
  hasAccessToSurveySummary: false,
  enableEquitableBehaviorsDataPipeline: false,
  enableEquitableBehaviorsDemoData: false,
  isFlagEnabled: () => false
})

export const MicroServiceProvider = (props: MicroServices): JSX.Element => {
  return (
    <MicroServiceContext.Provider value={props}>
      {props.children}
    </MicroServiceContext.Provider>
  )
}

import React from 'react'
import {
  Box,
  Grid,
  GridProps,
  Tooltip,
  Typography,
  styled
} from '@mui/material'
import { animated, easings, useSpring } from 'react-spring'

const MainContainer = styled(Grid)<GridProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
}))

const ResponseBarContainer = styled(animated.div)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  height: '15px',
  background: 'linear-gradient(270deg, #5F71E5 50%, #9DA7E8 99.61%)',
  borderTopRightRadius: '6px',
  borderBottomRightRadius: '6px',
  position: 'relative',
  zIndex: 1
}))

const PercentContainer = styled(Grid)<GridProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'right',
  padding: '5px 10px'
}))

interface IProps {
  department: string
  percentage: number
}

const ResponseBar = ({ department, percentage }: IProps) => {
  // Normalize the current score to a percentage based on the max width of the Foreground
  const responsePercentage = percentage
  const barAnimation = useSpring({
    from: {
      width: '0%'
    },
    to: {
      width: `${Number(responsePercentage)}%`
    },
    config: { duration: 2000, easing: easings.easeInOutQuart }
  })

  return (
    <MainContainer container>
      <Grid sx={{ padding: '5px 10px', position: 'relative' }} item xs={3}>
        <Box
          sx={{
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            position: 'relative',
            zIndex: 3
          }}
        >
          <Tooltip title={department} arrow placement='left'>
            <Typography
              variant='h7'
              sx={{
                textTransform: 'uppercase',
                textAlign: 'left',
                whiteSpace: 'nowrap',
                width: '100%',
                fontSize: '12px',
                letterSpacing: '0.17em',
                zIndex: 999,
                '&:hover': {
                  cursor: 'default'
                }
              }}
            >
              {department}
            </Typography>
          </Tooltip>
        </Box>
      </Grid>
      <Grid
        item
        xs={8}
        sx={{
          padding: '10px 0',
          borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
          borderRight: '1px solid rgba(0, 0, 0, 0.12)'
        }}
      >
        <ResponseBarContainer style={barAnimation} />
      </Grid>
      <PercentContainer item xs={1}>
        <Typography
          variant='h6'
          sx={{
            fontSize: '14px',
            letterSpacing: '0.01em',
            marginRight: '-10px'
          }}
        >
          {percentage}%
        </Typography>
      </PercentContainer>
    </MainContainer>
  )
}
export default ResponseBar

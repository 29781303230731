import React, { useRef, useEffect, useState } from 'react'
import { Box, Typography, Grid, useTheme, useMediaQuery } from '@mui/material'
import { Color } from 'components/SharedStyles'
import { CategorySummaryData } from 'model'
import { useSpring, animated, easings } from 'react-spring'

interface Props {
  strength: CategorySummaryData
  opportunity: CategorySummaryData
}

const EbCard = ({ strength, opportunity }: Props) => {
  const [isVisible, setIsVisible] = useState(false)
  const myRef = useRef<HTMLDivElement>(null!)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(987))

  useEffect(() => {
    if (isVisible) return
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0]
      setIsVisible(entry.isIntersecting)
    })
    observer.observe(myRef.current)
  }, [setIsVisible, isVisible])

  const [strengthScore, strengthHandler] = useSpring(() => ({
    score: 0
  }))
  const [opportunityScore, opportunityHandler] = useSpring(() => ({
    score: 0
  }))

  useEffect(() => {
    if (isVisible) {
      strengthHandler.start({
        score: strength.score,
        config: { duration: 2000, easing: easings.easeInOutQuart }
      })
      opportunityHandler.start({
        score: opportunity.score,
        config: { duration: 2000, easing: easings.easeInOutQuart }
      })
    }
  }, [
    isVisible,
    strength.score,
    opportunity.score,
    strengthHandler,
    opportunityHandler
  ])

  return (
    <Box ref={myRef} sx={{ display: 'flex', justifyContent: 'space-between' }}>
      {[strength, opportunity].map((data, index) => (
        <Grid
          item
          xs={5.8}
          key={index}
          sx={{
            border: `1px solid ${Color.grey300}`,
            borderRadius: '8px',
            padding: '16px 32px',
            height: '208px',
            backgroundColor: '#fff'
          }}
        >
          <Typography
            variant='h6'
            sx={{
              fontSize: '12px',
              textTransform: 'uppercase',
              letterSpacing: '0.17em',
              marginBottom: '24px'
            }}
          >
            {`Top ${index === 0 ? 'Strength' : 'Opportunity'}`}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                borderRadius: '50%',
                width: '80px',
                height: '80px',
                backgroundColor: index === 0 ? '#E2FFF6' : '#FFECED',
                padding: isSmallScreen ? '12px' : 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '24px',
                fontSize: '26px',
                fontWeight: 700,
                fontFamily: 'IBM Plex Sans'
              }}
            >
              <animated.h4
                style={{ fontSize: '26px', fontFamily: 'IBM Plex Sans' }}
              >
                {index === 0
                  ? strengthScore.score.to(score => score.toFixed(0))
                  : opportunityScore.score.to(score => score.toFixed(0))}
              </animated.h4>
              %
            </Box>
            <Typography
              variant='body1'
              sx={{
                width: '80%',
                fontSize: isSmallScreen ? '12px !important' : '1em'
              }}
            >
              {data.display_text}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Box>
  )
}

export default EbCard

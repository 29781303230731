/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react'
import { SurveySummaryContext } from 'context/SurveySummary'
import { Box, BoxProps, styled, Typography } from '@mui/material'
import ConstellationGraph from './components/ConstellationGraph'
import UserResponses from './UserResponses'

const UserResponsesContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '8px',
  paddingBottom: '16px'
}))

const ResponseWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: '#FFFFFF',
  marginBottom: '40px',
  padding: '40px',
  borderRadius: '8px',
  border: '1px solid rgba(0, 0, 0, 0.12)'
}))

const DefiningTrackingGraph = () => {
  const {
    responseRateData,
    surveyCountSummary,
    useDefaultGraph,
    definingTrackingData,
    showDigDeeper
  } = useContext(SurveySummaryContext)

  if (!responseRateData || !surveyCountSummary) return null

  if (!definingTrackingData) return null

  return useDefaultGraph ? (
    <UserResponsesContainer>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'left',
          paddingBottom: '16px'
        }}
      >
        <Typography variant='h5'>Defining and Tracking</Typography>
      </Box>
      <ResponseWrapper>
        <UserResponses
          useDefaultGraph={useDefaultGraph}
          responseRateData={definingTrackingData}
        />
      </ResponseWrapper>
    </UserResponsesContainer>
  ) : (
    <ConstellationGraph
      definingTrackingData={definingTrackingData}
      showDigDeeper={showDigDeeper}
    />
  )
}

export default DefiningTrackingGraph

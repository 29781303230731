import React, { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import EmployeeSurvey from 'pages/EmployeeSurvey'
import { MicroServiceContext } from 'context/MicroService'
import { SurveySummaryContext } from 'context/SurveySummary'
import EmptyPage from './EmptyPage'

const EmployeeSurveyRouter = () => {
  const { hasAccessToSurveySummary } = useContext(MicroServiceContext)
  const { startDate } = useContext(SurveySummaryContext)
  return (
    <Routes>
      <Route
        path=''
        element={
          hasAccessToSurveySummary && startDate ? (
            <EmployeeSurvey />
          ) : (
            <EmptyPage />
          )
        }
      />
    </Routes>
  )
}
export default EmployeeSurveyRouter

import * as React from 'react'
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  MenuItemProps,
  FormControl,
  Select,
  Typography,
  Tooltip
} from '@mui/material'
import { Color } from './SharedStyles'
import { styled } from '@mui/material/styles'

interface Props {
  name: string
  filterValues: any
  selection: string
  setSelection: React.Dispatch<any>
  setId: React.Dispatch<any>
  type: 'department' | 'role'
}

const FilterItem = styled(MenuItem)<MenuItemProps>(() => ({
  backgroundColor: `${Color.grey200} !important`,
  borderRadius: '16px',
  width: 'max-content',
  height: 'auto',
  marginRight: '6px',
  marginBottom: '12px',
  '&.Mui-selected': {
    backgroundColor: `${Color.main} !important`,
    color: 'white'
  }
}))
const Filter = ({
  name,
  filterValues,
  selection,
  setSelection,
  setId,
  type
}: Props) => {
  const handleChange = (event: any) => {
    const {
      target: { value }
    } = event
    setSelection(value)
    setId(
      type === 'department'
        ? filterValues.find((object: any) => object.dept_name === value)
            .dept_nano_id
        : filterValues.find((object: any) => object.role_level === value)
            .role_level_nano_id
    )
  }
  return (
    <div>
      <FormControl
        sx={{
          margin: '20px 24px 0 0',
          width: 215,
          '& .MuiOutlinedInput-root': {
            top: '-20px',
            borderRadius: '8px',
            backgroundColor: Color.grey50,
            border: `1px solid ${Color.grey300}`
          },
          '& fieldset': {
            border: 'none'
          },
          '& .MuiSelect-select': {
            padding: '13px 14px 10px 14px'
          }
        }}
      >
        <InputLabel
          id='label'
          sx={{
            fontFamily: 'IBM Plex Sans',
            fontWeight: 400,
            fontSize: '16px'
          }}
        >
          {name}
        </InputLabel>
        <Select
          labelId='label'
          value={selection}
          onChange={handleChange}
          input={<OutlinedInput label={name} />}
          renderValue={value => (
            <Typography variant='body16'>
              {value === 'all' && type === 'department'
                ? 'All departments'
                : value === 'all' && type === 'role'
                ? 'All role levels'
                : value}
            </Typography>
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: 66,
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            PaperProps: {
              sx: {
                maxWidth: 289,
                '& .MuiList-root': {
                  display: 'inline-flex',
                  flexWrap: 'wrap',
                  padding: '16px 6px 10px 16px'
                }
              }
            }
          }}
          sx={{
            padding: '12px 0 0 0'
          }}
        >
          {filterValues.map((data: any, index: number) => {
            const content =
              data.dept_name === 'all'
                ? 'All departments'
                : data.role_level === 'all'
                ? 'All role levels'
                : type === 'department'
                ? data.dept_name
                : data.role_level
            return (
              <FilterItem
                key={index}
                value={type === 'department' ? data.dept_name : data.role_level}
                disableRipple
              >
                <Tooltip title={content}>
                  <Typography
                    variant={
                      selection === data.dept_name ||
                      selection === data.role_level
                        ? 'body14'
                        : 'body14Light'
                    }
                    sx={{
                      maxWidth: 200,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {content}
                  </Typography>
                </Tooltip>
              </FilterItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}
export default Filter

/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
import React from 'react'
import { CategorySummaryData } from 'model'
import { Box, Typography, BoxProps, styled } from '@mui/material'
import GraphBar from './GraphBar'
import Percentages from './Percentages'

const ResponseWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: '#FFFFFF',
  marginBottom: '40px'
}))

interface IProps {
  responseRateData: CategorySummaryData[]
  useDefaultGraph: boolean | undefined
}

const UserResponses = ({ responseRateData, useDefaultGraph }: IProps) => {
  const summaryData: CategorySummaryData[] = responseRateData || []

  if (!useDefaultGraph) return null

  return (
    <ResponseWrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          marginBottom: '20px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography
            variant='body1'
            sx={{
              fontSize: '12px',
              fontWeight: '700',
              fontFamily: 'IBM Plex Sans'
            }}
          >
            ORGANIZATION AVERAGE
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          '.MuiGrid-container:first-child': {
            borderTop: '1px solid rgba(0, 0, 0, 0.12)'
          }
        }}
      >
        {summaryData.map((data, index) => (
          <GraphBar
            key={`question-${index}`}
            question={data.display_text}
            percentage={data.score}
          />
        ))}
      </Box>

      <Percentages responseData={summaryData} />
    </ResponseWrapper>
  )
}
export default UserResponses

/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Color } from 'components/SharedStyles'
import { SurveySummaryContext } from 'context/SurveySummary'
import EbButton from 'components/EbButton'
import OnboardingAdvancingCircleGraph from './OnboardingAdvancingCircleGraph'

const OnboardingAdvancing = () => {
  const { onboardingAdvancingData, showDigDeeper } =
    useContext(SurveySummaryContext)

  if (!onboardingAdvancingData) return <></>
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '8px',
          paddingBottom: '16px'
        }}
      >
        <Typography
          variant='h5'
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          Onboarding and Advancing
        </Typography>
        <EbButton
          showButton={showDigDeeper}
          url='/equity-index/employee-survey/equitable-behaviors/onboarding-advancing'
        >
          Dig deeper
        </EbButton>
      </Box>
      <Grid
        container
        item
        xs={12}
        sx={{
          border: `1px solid ${Color.grey300}`,
          borderRadius: '8px',
          padding: '32px 0',
          backgroundColor: '#FFFFFF'
        }}
      >
        {onboardingAdvancingData.map((data, index) => (
          <OnboardingAdvancingCircleGraph
            key={index}
            index={index}
            displayText={data.display_text}
            score={data.score}
          />
        ))}
      </Grid>
    </>
  )
}

export default OnboardingAdvancing

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
import React from 'react'
import { createRoot, Root } from 'react-dom/client'
import './index.css'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { theme } from 'components/SharedStyles'
import { MicroServiceProvider } from 'context/MicroService'
import { MicroServices } from './model/microServices'
import App from './App'

interface Props {
  container?: any
}
let root: Root
const render = (props: MicroServices & Props) => {
  const {
    container,
    displayNotification,
    maestroClient,
    currentAccount,
    hasAccessToSurveySummary,
    enableEquitableBehaviorsDataPipeline,
    enableEquitableBehaviorsDemoData,
    isFlagEnabled
  } = props
  root = createRoot(
    container
      ? container.querySelector('#fender-root')
      : document.getElementById('fender-root')
  )
  root.render(
    <React.StrictMode>
      <MicroServiceProvider
        displayNotification={displayNotification}
        maestroClient={maestroClient}
        currentAccount={currentAccount}
        hasAccessToSurveySummary={hasAccessToSurveySummary}
        enableEquitableBehaviorsDataPipeline={
          enableEquitableBehaviorsDataPipeline
        }
        enableEquitableBehaviorsDemoData={enableEquitableBehaviorsDemoData}
        isFlagEnabled={isFlagEnabled}
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </MicroServiceProvider>
    </React.StrictMode>
  )
}

export const bootstrap = async () => {}

export const mount = async (props: MicroServices) => {
  render(props)
}

export const unmount = async () => {
  root.unmount()
}

export const update = async (props: any) => {
  render(props)
}

if (!(window as any).__POWERED_BY_QIANKUN__) {
  render({
    displayNotification: () => {},
    currentAccount: {
      id: '',
      employer: { name: '', code: '' },
      user: { id: '', name: '', primaryEmail: '' }
    },
    maestroClient: undefined,
    children: undefined,
    hasAccessToSurveySummary: false,
    enableEquitableBehaviorsDataPipeline: false,
    enableEquitableBehaviorsDemoData: false,
    isFlagEnabled: () => false
  })
}

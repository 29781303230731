import { EdgeOpp, EdgeStrength } from 'model'

export enum MyTabs {
  TopStrengths,
  TopOpportunities
}

export const strengths: EdgeStrength = {
  employeeBehaviorsTopStrengths: {
    edges: [
      {
        category: 'A&S',
        strengths: [
          {
            pctValue: '66%',
            questionText:
              'Which of the following hiring practices do you follow regarding pay transparency for job candidates?',
            questionDisplayText:
              'of employees include salary ranges when publishing job postings at least some of the time.',
            denominator: '175'
          }
        ],
        surveyId: 'ceb95e22-eafd-4c00-8740-8cf351edbf4b',
        updatedAt: '2023-01-21T06:21:08'
      },
      {
        category: 'D&T',
        strengths: [
          {
            pctValue: '87%',
            questionText:
              "Are you familiar with your organization's DEI goals?",
            questionDisplayText:
              "of employees are familiar with your organization's diversity, equity, and inclusion goals",
            denominator: '280'
          }
        ],
        surveyId: 'ceb95e22-eafd-4c00-8740-8cf351edbf4b',
        updatedAt: '2023-01-21T06:21:08'
      },
      {
        category: 'I&E',
        strengths: [
          {
            pctValue: '54%',
            questionText:
              'Do you submit interview feedback independently or before reviewing the feedback of others?',
            questionDisplayText:
              'of employees submit interview feedback before reviewing the feedback of others, at least some of the time.',
            denominator: '175'
          }
        ],
        surveyId: 'ceb95e22-eafd-4c00-8740-8cf351edbf4b',
        updatedAt: '2023-01-21T06:21:08'
      },
      {
        category: 'O&A',
        strengths: [
          {
            pctValue: '76%',
            questionText:
              'Have you been recognized for your contributions at work in the past 3 months?',
            questionDisplayText:
              "of employees felt they've been recognized for contributions at work, either formally or informally, within the last 3 months.",
            denominator: '280'
          }
        ],
        surveyId: 'ceb95e22-eafd-4c00-8740-8cf351edbf4b',
        updatedAt: '2023-01-21T06:21:08'
      }
    ]
  }
}

export const opportunities: EdgeOpp = {
  employeeBehaviorsTopOpportunities: {
    edges: [
      {
        category: 'A&S',
        opps: [
          {
            pctValue: '19%',
            questionText:
              "Do you see people like you represented on your organization's website and social media?",
            questionDisplayText:
              "of employees feel people like them are represented on your organization's website and social media.",
            denominator: '175'
          }
        ],
        surveyId: 'ceb95e22-eafd-4c00-8740-8cf351edbf4b',
        updatedAt: '2023-01-21T06:21:08'
      },
      {
        category: 'D&T',
        opps: [
          {
            pctValue: '25%',
            questionText:
              'Are you held accountable for helping the organization achieve its diversity goals?',
            questionDisplayText:
              'of employees feel accountable for helping your organization achieve your DEI goals.',
            denominator: '280'
          }
        ],
        surveyId: 'ceb95e22-eafd-4c00-8740-8cf351edbf4b',
        updatedAt: '2023-01-21T06:21:08'
      },
      {
        category: 'I&E',
        opps: [
          {
            pctValue: '22%',
            questionText:
              'Do you follow a structured process when interviewing candidates, such as defined interview states, key stakeholders, and consistent questions?',
            questionDisplayText:
              'of employees follow a structured interview process when interviewing candidates at least some of the time.',
            denominator: '175'
          }
        ],
        surveyId: 'ceb95e22-eafd-4c00-8740-8cf351edbf4b',
        updatedAt: '2023-01-21T06:21:08'
      },
      {
        category: 'O&A',
        opps: [
          {
            pctValue: '18%',
            questionText:
              'As part of your onboarding process, were you provided a personalized onboarding plan?',
            questionDisplayText:
              'of employees were provided a personalized onboarding plan as a new hire at the organization.',
            denominator: '280'
          }
        ],
        surveyId: 'ceb95e22-eafd-4c00-8740-8cf351edbf4b',
        updatedAt: '2023-01-21T06:21:08'
      }
    ]
  }
}

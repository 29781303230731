export const colorMapping: any = {
  'Neurodiverse Community': {
    color: '#FFC165',
    borderColor: '#FCA625'
  },
  Women: {
    color: '#BDC6FF',
    borderColor: '#1A2DA6'
  },
  'Asian & Pacific Islander': {
    color: '#EBF2A1',
    borderColor: '#C3D12C'
  },
  'Older & Experienced Workers': {
    color: '#E5E5E5',
    borderColor: '#373C3F'
  },
  Veterans: {
    color: '#D3F8FF',
    borderColor: '#4CD6F1'
  },
  'Refugees & Immigrants': {
    color: '#FFD699',
    borderColor: '#FFAD33'
  },
  'LGBTQ+ Community': {
    color: '#EFCAF9',
    borderColor: '#9C28A7'
  },
  'People with disabilities': {
    color: '#BDC6FF',
    borderColor: '#3148D3'
  },
  'Black community': {
    color: '#FFCBCB',
    borderColor: '#FE6868'
  },
  'Working Parents': {
    color: '#B5F2DF',
    borderColor: '#32B996'
  },
  'Hispanic & Latinx Community ': {
    color: '#D3F8FF',
    borderColor: '#4CD6F1'
  },
  'Indigenous and First nations': {
    color: '#FFCBCB',
    borderColor: '#FE6868'
  }
}

export const mockData: any = {
  filters: {
    departments: [
      {
        dept_nano_id: 'all',
        dept_name: 'all'
      },
      {
        dept_nano_id: '4001',
        dept_name: 'Sales'
      }
    ],
    role_levels: [
      {
        role_level_nano_id: 'all',
        role_level: 'all'
      },
      {
        role_level_nano_id: '10711',
        role_level: 'Level 2'
      }
    ]
  },
  data: {
    all_all: [
      {
        group_name: 'Neruodiverse Community',
        percentage_value: 60,
        display: true
      },
      { group_name: 'Women', percentage_value: 17, display: true },
      {
        group_name: 'Asian & Pacific Islander',
        percentage_value: 14,
        display: true
      },
      {
        group_name: 'Older & Experienced Workers',
        percentage_value: 13,
        display: true
      },
      { group_name: 'Veterans', percentage_value: 10, display: true },
      {
        group_name: 'Refugees & Immigrants',
        percentage_value: 10,
        display: true
      },
      { group_name: 'LGBTQ+ Community', percentage_value: 7, display: true },
      {
        group_name: 'People with Disabilities',
        percentage_value: 4,
        display: true
      },
      { group_name: 'Black Community', percentage_value: 3, display: true },
      { group_name: 'Working Parents', percentage_value: 2, display: true },
      {
        group_name: 'Hispanic & LatinX Community',
        percentage_value: 1,
        display: true
      },
      {
        group_name: 'Indigenous & First Nations',
        percentage_value: 1,
        display: true
      }
    ],
    all_10711: [
      {
        group_name: 'Indigenous & First Nations',
        percentage_value: 18,
        display: true
      },
      {
        group_name: 'Hispanic & LatinX Community',
        percentage_value: 17,
        display: true
      },
      { group_name: 'Working Parents', percentage_value: 16, display: true },
      { group_name: 'Black Community', percentage_value: 15, display: true },
      {
        group_name: 'People with Disabilities',
        percentage_value: 14,
        display: true
      },
      { group_name: 'LGBTQ+ Community', percentage_value: 13, display: true },
      {
        group_name: 'Refugees & Immigrants',
        percentage_value: 12,
        display: true
      },
      { group_name: 'Veterans', percentage_value: 10, display: true },
      {
        group_name: 'Older & Experienced Workers',
        percentage_value: 6,
        display: true
      },
      {
        group_name: 'Asian & Pacific Islander',
        percentage_value: 4,
        display: true
      },
      { group_name: 'Women', percentage_value: 2, display: true },
      {
        group_name: 'Neruodiverse Community',
        percentage_value: 1,
        display: true
      }
    ],
    '4001_all': [
      {
        group_name: 'Indigenous & First Nations',
        percentage_value: 18,
        display: true
      },
      {
        group_name: 'Hispanic & LatinX Community',
        percentage_value: 17,
        display: true
      },
      { group_name: 'Working Parents', percentage_value: 16, display: true },
      { group_name: 'Black Community', percentage_value: 15, display: true },
      {
        group_name: 'People with Disabilities',
        percentage_value: 14,
        display: true
      },
      { group_name: 'LGBTQ+ Community', percentage_value: 13, display: true },
      {
        group_name: 'Neruodiverse Community',
        percentage_value: 10,
        display: true
      },
      { group_name: 'Women', percentage_value: 10, display: true },
      {
        group_name: 'Asian & Pacific Islander',
        percentage_value: 10,
        display: true
      },
      {
        group_name: 'Older & Experienced Workers',
        percentage_value: 10,
        display: true
      },
      { group_name: 'Veterans', percentage_value: 10, display: true },
      {
        group_name: 'Refugees & Immigrants',
        percentage_value: 10,
        display: true
      }
    ],
    '4001_10711': [
      {
        group_name: 'Refugees & Immigrants',
        percentage_value: 12,
        display: true
      },
      { group_name: 'LGBTQ+ Community', percentage_value: 12, display: true },
      {
        group_name: 'People with Disabilities',
        percentage_value: 12,
        display: true
      },
      { group_name: 'Black Community', percentage_value: 12, display: true },
      { group_name: 'Working Parents', percentage_value: 12, display: true },
      {
        group_name: 'Hispanic & LatinX Community',
        percentage_value: 12,
        display: true
      },
      { group_name: 'Indigenous & First Nations', percentage_value: 12 },
      { group_name: 'Veterans', percentage_value: 10 },
      { group_name: 'Older & Experienced Workers', percentage_value: 6 },
      { group_name: 'Asian & Pacific Islander', percentage_value: 4 },
      { group_name: 'Women', percentage_value: 2 },
      { group_name: 'Neruodiverse Community', percentage_value: 1 }
    ]
  }
}

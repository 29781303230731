import React, { useContext } from 'react'
import RouteProvider from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { MicroServiceContext } from 'context/MicroService'

const App = () => {
  const { maestroClient } = useContext(MicroServiceContext)

  return (
    <ApolloProvider client={maestroClient}>
      <Router>
        <RouteProvider />
      </Router>
    </ApolloProvider>
  )
}

export default App

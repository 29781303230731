import React from 'react'
import { styled } from '@mui/material'

const PercentBarWrapper = styled('div')(() => ({
  position: 'relative',
  width: '100%'
}))

const PercentBarContainer = styled('div')(
  ({
    percent,
    numberOfDepartments
  }: {
    percent: number
    numberOfDepartments: number
  }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: `${numberOfDepartments * 36}px`,
    background: 'transparent',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    position: 'absolute',
    top: `-${numberOfDepartments * 36}px`,
    zIndex: 0,
    width: `${Number(percent)}%`
  })
)

interface IProps {
  percent: number
  numberOfDepartments: number
}

const PercentBar = ({ percent, numberOfDepartments }: IProps) => {
  return (
    <PercentBarWrapper>
      <PercentBarContainer
        percent={percent}
        numberOfDepartments={numberOfDepartments}
      />
    </PercentBarWrapper>
  )
}
export default PercentBar

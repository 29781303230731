/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect, useContext, useState } from 'react'
// @ts-ignore
import * as d3 from 'd3'
import { Box, Typography, BoxProps } from '@mui/material'
import { Color } from 'components/SharedStyles'
import {
  createSvg,
  createHorizontalBackgroundLines,
  createVerticalBackgroundLines,
  createHorizontalBars
} from 'utils/d3Utils'
import { useSpring, animated, easings } from 'react-spring'
import { styled } from '@mui/material/styles'
import { CategorySummaryData } from 'model'
import EbButton from 'components/EbButton'

const DisplayTextContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '220px',
  position: 'relative',
  height: '150px',
  justifyContent: 'space-around',
  top: -140
}))

const PercentageLabelContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '745px',
  marginLeft: '180px'
}))

const AveragesContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  marginRight: '35px',
  position: 'relative',
  height: '150px',
  justifyContent: 'space-around',
  top: -290
}))

const DefiningTrackingContainer = styled(Box)<BoxProps>(() => ({
  width: '100%',
  padding: '32px',
  borderRadius: '8px',
  border: `1px solid ${Color.grey300}`,
  backgroundColor: Color.white,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}))

const addBoldItalic = (match: string) => {
  return `<b><i>${match}</i></b>`
}

interface IProps {
  definingTrackingData: CategorySummaryData[] | null
  showDigDeeper: boolean
}

const ConstellationGraph = ({
  definingTrackingData,
  showDigDeeper
}: IProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const myRef = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    if (isVisible || !definingTrackingData) return
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setIsVisible(entry.isIntersecting)
      }
    })
    observer.observe(myRef.current)
  }, [setIsVisible, isVisible, definingTrackingData])

  const [strengthScore1, strengthScore1handler] = useSpring(() => ({
    score: 0
  }))

  useEffect(() => {
    if (isVisible) {
      strengthScore1handler.start({
        score: definingTrackingData ? definingTrackingData[0]?.score : 0,
        config: { duration: 2000, easing: easings.easeInOutQuart }
      })
    }
  }, [isVisible, definingTrackingData, strengthScore1handler])

  const svgRef = useRef()

  useEffect(() => {
    const svg = d3
      // @ts-ignore
      .select(svgRef.current)
    svg.selectAll('*').remove()
  }, [])

  useEffect(() => {
    if (isVisible) {
      const width = 1125
      const height = 100
      const svg = createSvg(svgRef, width, height).style('overflow', 'visible')

      createHorizontalBackgroundLines(svg, 1, 83, height, width)
      createVerticalBackgroundLines(svg, 5, 180.5, height + 10, width - 128)
      createHorizontalBars(
        svg,
        definingTrackingData,
        -56,
        275,
        83,
        8,
        1000,
        width,
        false,
        '',
        '#9DA7E8',
        '#5F71E5'
      )
    }
  }, [definingTrackingData, isVisible])

  if (!definingTrackingData) return <></>

  const strengthScores: any[] = []

  if (definingTrackingData) {
    definingTrackingData.forEach((strength, index) => {
      if (index === 0) strengthScores.push(strengthScore1.score)
    })
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '8px',
          paddingBottom: '16px'
        }}
      >
        <Typography
          variant='h5'
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          Defining and Tracking
        </Typography>
        <EbButton
          showButton={showDigDeeper}
          url='/equity-index/employee-survey/equitable-behaviors/defining-tracking'
        >
          Dig deeper
        </EbButton>
      </Box>

      <DefiningTrackingContainer ref={myRef}>
        <Box sx={{ height: '145px', width: '1125px' }}>
          <Typography
            variant='h4'
            sx={{
              fontSize: '12px',
              textTransform: 'uppercase',
              textAlign: 'right',
              marginRight: '12px'
            }}
          >
            Organization Avg
          </Typography>
          <svg
            // @ts-ignore
            ref={svgRef}
            className='svgElement'
          />
          <DisplayTextContainer>
            {definingTrackingData.map(strength => (
              <Typography
                key={strength.display_text}
                variant='body1'
                sx={{
                  fontSize: '14px'
                }}
                dangerouslySetInnerHTML={{
                  __html: `% ${strength.display_text.replaceAll(
                    /familiar|responsible|accountable/gi,
                    addBoldItalic
                  )}`
                }}
              />
            ))}
          </DisplayTextContainer>
          <AveragesContainer>
            {strengthScores.map((avg: any, index: number) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <animated.h3 style={{ lineHeight: '18px', fontSize: '18px' }}>
                  {avg.to((positionAvg: number) => positionAvg.toFixed(0))}
                </animated.h3>
                <Typography variant='h6'>%</Typography>
              </Box>
            ))}
          </AveragesContainer>
        </Box>
        <PercentageLabelContainer>
          {['0%', '25%', '50%', '75%', '100%'].map(
            (num: string, index: number) => (
              <Typography
                variant='h4'
                key={index}
                sx={{ fontSize: '12px', color: Color.grey600 }}
              >
                {num}
              </Typography>
            )
          )}
        </PercentageLabelContainer>
      </DefiningTrackingContainer>
    </>
  )
}

export default ConstellationGraph

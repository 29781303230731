import { Grid, Typography } from '@mui/material'
import React from 'react'

type Props = {
  title: string
  content: string
  imageUrl: string
}
const Block = ({ title, content, imageUrl }: Props) => {
  return (
    <Grid xs={3.3} item>
      <div style={{ height: 260, position: 'relative' }}>
        <img
          src={imageUrl}
          alt='progress'
          style={{
            margin: 0,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)'
          }}
        ></img>
      </div>
      <Typography variant='h5'>{title}</Typography>
      <Typography variant='body1' marginTop={'16px'}>
        {content}
      </Typography>
    </Grid>
  )
}
export default Block

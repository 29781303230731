import React, { useEffect, useState, useRef } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { useSpring, animated, easings } from 'react-spring'
import { colorMapping } from './constants'
interface Props {
  community: any
  largestCommunityPercentage: any
}

const BubbleChart = ({ community, largestCommunityPercentage }: Props) => {
  const [isVisible, setIsVisible] = useState(false)
  const myRef = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    if (isVisible) return
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setIsVisible(entry.isIntersecting)
      }
    })
    observer.observe(myRef.current)
  }, [setIsVisible, isVisible])

  const circleStyling = {
    borderRadius: '50%',
    backgroundColor: colorMapping[community.group_name]?.color,
    border: `1px solid ${colorMapping[community.group_name]?.borderColor}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '16px'
  }
  const largestSqrtPercentage = Math.sqrt(largestCommunityPercentage)
  const ratioOfLargestPercentage = 190 / largestSqrtPercentage

  const [style, styleHandler] = useSpring(() => ({
    width: '0px',
    height: '0px',
    ...circleStyling
  }))

  const [percent, percentHandler] = useSpring(() => ({
    percent: 0
  }))

  useEffect(() => {
    percentHandler.set({ percent: 0 })
    if (isVisible) {
      styleHandler.start({
        width: `${
          Math.sqrt(community.percentage_value) * ratioOfLargestPercentage
        }px`,
        height: `${
          Math.sqrt(community.percentage_value) * ratioOfLargestPercentage
        }px`,
        ...circleStyling,
        config: { duration: 2000, easing: easings.easeInOutQuart }
      })
      percentHandler.start({
        percent: community.percentage_value,
        config: { duration: 1000 }
      })
    }
  }, [isVisible, community.percentage_value, largestCommunityPercentage])

  return (
    <Box
      ref={myRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 40px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '196px'
        }}
      >
        {community.percentage_value > 0 && <animated.div style={style} />}
      </Box>
      <Divider />
      <Box sx={{ minHeight: '100px', marginBottom: '16px' }}>
        <Typography
          variant='h4'
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& p': {
              margin: '16px 0'
            }
          }}
        >
          <animated.p>
            {percent.percent.to(p => Math.round(p * 10) / 10)}
          </animated.p>
          %
        </Typography>
        <Typography
          variant='body14'
          sx={{
            textTransform: 'uppercase'
          }}
        >
          {community.group_name}
        </Typography>
      </Box>
    </Box>
  )
}

export default BubbleChart

/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from 'react'
import {
  Box,
  Typography,
  TypographyProps,
  Button,
  Grid,
  GridProps,
  ButtonProps,
  BoxProps
} from '@mui/material'
import { Color, theme } from '@mathison-inc/components'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { styled } from '@mui/material/styles'
import { SurveySummaryContext } from 'context/SurveySummary'
import moment from 'moment'
import DepartmentResponses from './DepartmentResponses'

const ResponseRateContainer = styled(Box)<BoxProps>(() => ({
  width: '100%',
  border: `1px solid ${Color.grey300}`,
  borderRadius: '8px',
  padding: '44px 32px 40px',
  backgroundColor: Color.white
}))

interface HeaderDataItemProps extends GridProps {
  title: string
}

const ResponseRateHeaderDataItem = styled(Grid, {
  shouldForwardProp: prop => prop !== 'title'
})<HeaderDataItemProps>(({ title }) => ({
  display: 'flex',
  maxWidth: '100%',
  alignItems: 'center',
  borderRight:
    title !== 'Total Completed' ? `1px solid ${Color.grey300}` : 'none',
  justifyContent:
    title === 'Total Sent' || title === 'Responded Count'
      ? 'flex-start'
      : title === 'Total Completed'
      ? 'flex-end'
      : 'center'
}))

const CircularIconButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: Color.grey100,
  minWidth: '40px',
  height: '40px',
  borderRadius: '100%',
  padding: '0px'
}))

const StyledTitle = styled(Typography)<TypographyProps>(() => ({
  marginRight: '16px',
  textTransform: 'uppercase',
  fontSize: '12px',
  whiteSpace: 'nowrap',
  letterSpacing: '0.17em',
  [theme.breakpoints.down(1240)]: {
    fontSize: '10px',
    marginRight: '8px',
    letterSpacing: '0.15em'
  },
  [theme.breakpoints.down(1035)]: {
    fontSize: '8px',
    marginRight: '5px',
    letterSpacing: '0.10em'
  }
}))

const StyledValue = styled(Typography)<TypographyProps>(() => ({
  margin: 0,
  fontFamily: 'IBM Plex Sans',
  fontSize: '36px',
  fontWeight: '700',
  lineHeight: '40px',
  letterSpacing: '-0.02em',
  color: '#000',
  [theme.breakpoints.down(1240)]: {
    fontSize: '25px'
  },
  [theme.breakpoints.down(1035)]: {
    fontSize: '20px'
  }
}))

const ResponseRate = () => {
  const {
    responseRateData,
    surveyCountSummary,
    startDate,
    endDate,
    useDefaultGraph
  } = useContext(SurveySummaryContext)
  const [isOtherDepartmentsVisible, setIsOtherDepartmentsVisible] =
    useState(false)

  if (!responseRateData || !surveyCountSummary) return <></>

  return (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', marginBottom: '24px' }}
      >
        <Typography variant='h4' sx={{ marginBottom: '8px' }}>
          Response Rate
        </Typography>
        <Typography
          variant='body1'
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {`Responses from ${moment(startDate).format('LL')} - ${moment(
            endDate
          ).format('LL')}`}
        </Typography>
      </Box>
      {surveyCountSummary.responded_count ? (
        <ResponseRateContainer>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Grid container>
              <ResponseRateHeaderDataItem item xs={4} title='Responded Count'>
                <StyledTitle variant='h7'>Total Responded</StyledTitle>
                <StyledValue variant='h1'>
                  {surveyCountSummary.responded_count
                    ? surveyCountSummary.responded_count.toLocaleString()
                    : 'na'}
                </StyledValue>
              </ResponseRateHeaderDataItem>
              <ResponseRateHeaderDataItem item xs={4} title='Completed Count'>
                <StyledTitle variant='h7'>Total Completed</StyledTitle>
                <StyledValue variant='h1'>
                  {surveyCountSummary.completed_count
                    ? surveyCountSummary.completed_count.toLocaleString()
                    : 'na'}
                </StyledValue>
              </ResponseRateHeaderDataItem>
              <ResponseRateHeaderDataItem item xs={4} title='Total Completed'>
                <StyledTitle variant='h7'>Percent Completed</StyledTitle>
                <StyledValue variant='h1'>
                  {`${surveyCountSummary.completed_percentage}%`}
                </StyledValue>
              </ResponseRateHeaderDataItem>
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              borderBottom:
                responseRateData.length > 5
                  ? `1px dashed ${Color.grey300}`
                  : 'none',
              paddingBottom: '24px'
            }}
          >
            <DepartmentResponses
              useDefaultGraph={useDefaultGraph}
              averageResponseRate={surveyCountSummary.completed_percentage}
              responseRateData={
                isOtherDepartmentsVisible || responseRateData.length < 5
                  ? responseRateData
                  : responseRateData.slice(0, 5)
              }
            />
          </Box>
          {responseRateData.length > 5 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingTop: '24px'
              }}
            >
              <Typography variant='body1Medium' sx={{ fontSize: '18px' }}>
                {!isOtherDepartmentsVisible
                  ? 'See other departments'
                  : 'Hide other departments'}
              </Typography>
              <CircularIconButton
                onClick={() =>
                  setIsOtherDepartmentsVisible(!isOtherDepartmentsVisible)
                }
              >
                {!isOtherDepartmentsVisible ? <AddIcon /> : <RemoveIcon />}
              </CircularIconButton>
            </Box>
          )}
        </ResponseRateContainer>
      ) : (
        <ResponseRateContainer>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Grid container>
              <ResponseRateHeaderDataItem item xs={4} title='Total Sent'>
                <StyledTitle variant='h7'>Total Sent</StyledTitle>
                <StyledValue variant='h1'>
                  {surveyCountSummary.total_sent
                    ? surveyCountSummary.total_sent.toLocaleString()
                    : 'na'}
                </StyledValue>
              </ResponseRateHeaderDataItem>
              <ResponseRateHeaderDataItem item xs={4} title='Total Responded'>
                <StyledTitle variant='h7'>Total Responded</StyledTitle>
                <StyledValue variant='h1'>
                  {`${surveyCountSummary.responded_percentage}%`}
                </StyledValue>
              </ResponseRateHeaderDataItem>
              <ResponseRateHeaderDataItem item xs={4} title='Total Completed'>
                <StyledTitle variant='h7'>Total Completed</StyledTitle>
                <StyledValue variant='h1'>
                  {`${surveyCountSummary.completed_percentage}%`}
                </StyledValue>
              </ResponseRateHeaderDataItem>
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              borderBottom:
                responseRateData.length > 5
                  ? `1px dashed ${Color.grey300}`
                  : 'none',
              paddingBottom: '24px'
            }}
          >
            <DepartmentResponses
              useDefaultGraph
              averageResponseRate={surveyCountSummary.completed_percentage}
              responseRateData={
                isOtherDepartmentsVisible || responseRateData.length < 5
                  ? responseRateData
                  : responseRateData.slice(0, 5)
              }
            />
          </Box>
          {responseRateData.length > 5 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingTop: '24px'
              }}
            >
              <Typography variant='body1Medium' sx={{ fontSize: '18px' }}>
                {!isOtherDepartmentsVisible
                  ? 'See other departments'
                  : 'Hide other departments'}
              </Typography>
              <CircularIconButton
                onClick={() =>
                  setIsOtherDepartmentsVisible(!isOtherDepartmentsVisible)
                }
              >
                {!isOtherDepartmentsVisible ? <AddIcon /> : <RemoveIcon />}
              </CircularIconButton>
            </Box>
          )}
        </ResponseRateContainer>
      )}
    </>
  )
}

export default ResponseRate

import { gql } from '@apollo/client'

export const GET_EMPLOYEE_SURVEY_SUMMARY = gql`
  query GetSurveySummary {
    employerSurveySummary {
      startAt
      endAt
      categorySummary
      departmentSummary
      representationSummary
      surveyCountSummary
    }
  }
`

import React, { useEffect, useState, useContext } from 'react'
import { MicroServiceContext } from 'context/MicroService'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import ResponseRate from 'components/Graphics/ResponseRate'
import EmployeeRepresentation from 'components/Graphics/EmployeeRepresentation'
import InclusionBelongingGraph from 'components/Graphics/InclusionBelongingGraph'
import DefiningTrackingGraph from 'components/Graphics/DefiningTrackingGraph'
import EquitableBehaviorsSection from 'components/Graphics/EquitableBehaviorsSection'
import OnboardingAdvancing from 'components/Graphics/OnboardingAdvancing'
import UserAlert from 'components/UserAlert'
import { USER_ALERT, USER_ALERT_DISABLED } from './constants'

const EmployeeSurvey = () => {
  const { isFlagEnabled } = useContext(MicroServiceContext)
  const surveyIsEnabled = isFlagEnabled('enable_employee_survey')
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
    document.title = 'Employee Survey | Mathison'
    return () => {
      document.title = 'Mathison'
    }
  }, [])

  return (
    <>
      <UserAlert
        userAlert={surveyIsEnabled ? USER_ALERT : USER_ALERT_DISABLED}
        hoursToHide={2}
      />
      <Typography variant='h1'>Survey Summary</Typography>
      <Grid container rowSpacing={3} sx={{ width: '100%', marginTop: 1 }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', width: '100%' }}>
            <CircularProgress
              size={60}
              sx={{ margin: 'auto', marginTop: '100px' }}
            />
          </Box>
        ) : (
          <>
            <Grid item xs={12}>
              <ResponseRate />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <EmployeeRepresentation />
            </Grid>
            <Grid item xs={12}>
              <InclusionBelongingGraph />
            </Grid>
            <Grid item xs={12}>
              <EquitableBehaviorsSection />
            </Grid>
            <Grid item xs={12}>
              <DefiningTrackingGraph />
            </Grid>
            <Grid item xs={12}>
              <OnboardingAdvancing />
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default EmployeeSurvey

import { Color } from '@mathison-inc/components'
import { Tabs, TabsProps, styled } from '@mui/material'

export const StyledTabs = styled(Tabs)<TabsProps>(() => ({
  '.MuiButtonBase-root': {
    textTransform: 'none',
    fontSize: '20px',
    color: Color.text
  },
  '.Mui-selected': {
    fontWeight: 700
  },
  '.MuiTabs-scroller': {
    '& .MuiTabs-indicator': {
      backgroundColor: 'black !important'
    }
  }
}))

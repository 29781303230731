/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

interface IProps {
  url: string
  children: React.ReactNode | undefined
  showButton: boolean
}

const StyledButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: '#FFFFFF',
  borderColor: '#f1f1f1',
  borderRadius: '8px',
  minWidth: '102px',
  padding: '10px'
}))

const EbButton = ({ url, children, showButton }: IProps) => {
  return (
    <>
      {showButton ? (
        <Link to={url}>
          <StyledButton variant='outlined'>{children}</StyledButton>
        </Link>
      ) : null}
    </>
  )
}
export default EbButton

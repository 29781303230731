import React, { useEffect, useState } from 'react'
// import {useLocation} from "react-router-dom";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  styled,
  BoxProps,
  Grid
} from '@mui/material'
import { EdgeStrength, EdgeOpp } from 'model/index'
import TopStrengths from './TopStrengths'
import TopOpportunities from './TopOpportunity'

interface IProps {
  edgeStrength: EdgeStrength
  edgeOpp: EdgeOpp
  selectedTabIndex: any
  selectedMainTab: any
}

const TabContainer = styled(Box)<BoxProps>(() => ({
  padding: 4,
  display: 'flex'
}))

const StrengthsContainer = styled(Grid)({
  border: '1px solid #E5E5E5',
  borderRadius: '8px',
  backgroundColor: '#FFF',
  width: '100%',
  height: '240px',
  margin: 'auto',
  marginTop: '-5px',
  marginLeft: 0
})

const TopComponent = ({
  edgeStrength,
  edgeOpp,
  selectedTabIndex,
  selectedMainTab
}: IProps): React.ReactElement => {
  const [selectedTab, setSelectedTab] = useState(0)
  const [tabSelected, setTabSelected] = useState('')

  const topStrengths = edgeStrength.employeeBehaviorsTopStrengths.edges
  const topOpportunities = edgeOpp.employeeBehaviorsTopOpportunities.edges

  const hasStrengths = topStrengths.length >= 2
  const hasOpportunities = topOpportunities.length >= 2

  const handleTabChange = (_e: React.SyntheticEvent, value: number) => {
    setSelectedTab(value)
  }

  useEffect(() => {
    switch (selectedTabIndex) {
      case 0:
        setTabSelected('D&T')
        break
      case 1:
        setTabSelected('A&S')
        break
      case 2:
        setTabSelected('I&E')
        break
      case 3:
        setTabSelected('O&A')
        break
      default:
        break
    }
  }, [selectedTabIndex])

  return (
    <>
      {!hasStrengths || !hasOpportunities ? (
        <TabContainer>
          <Tabs
            value={selectedTab}
            style={{
              // marginTop: 24,
              marginRight: 35
            }}
            onChange={handleTabChange}
          >
            {hasStrengths && (
              <Tab
                label={
                  <Typography
                    variant='tableBold'
                    fontWeight='500'
                    style={{ marginTop: '20px' }}
                  >
                    Top Strengths
                  </Typography>
                }
              />
            )}
            {hasOpportunities && (
              <Tab
                label={
                  <Typography
                    variant='tableBold'
                    fontWeight='500'
                    style={{ marginTop: '20px', marginLeft: '26px' }}
                  >
                    Top Opportunities
                  </Typography>
                }
              />
            )}
          </Tabs>
        </TabContainer>
      ) : null}
      {hasStrengths && hasOpportunities ? (
        <StrengthsContainer
          style={{
            marginTop: '16px',
            padding: '32px'
          }}
        >
          <div>
            <Typography
              style={{
                paddingBottom: '32px',
                textTransform: 'uppercase',
                fontWeight: 700,
                fontSize: '12px',
                letterSpacing: '0.17em'
              }}
            >
              % of employees that answered yes
            </Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '128px'
              }}
            >
              {(selectedTab === 0 || selectedTab === 1) &&
                topStrengths
                  .filter(str => str.category === tabSelected)
                  .map(strength => (
                    <TopStrengths
                      key={strength.category}
                      questionText={strength.strengths.map(
                        str => str.questionDisplayText
                      )}
                      selectedMainTab={selectedMainTab}
                      percent={strength.strengths.map(num => num.pctValue)}
                      denominator={strength.strengths.map(
                        dem => dem.denominator
                      )}
                      topStrengthTotal={1}
                      topOppTotal={1}
                    />
                  ))}
              {(selectedTab === 0 || selectedTab === 1) &&
                topOpportunities
                  .filter(str => str.category === tabSelected)
                  .map(opportunity => (
                    <TopOpportunities
                      key={opportunity.category}
                      questionText={opportunity.opps.map(
                        opp => opp.questionDisplayText
                      )}
                      selectedMainTab={selectedMainTab}
                      percent={opportunity.opps.map(num => num.pctValue)}
                      denominator={opportunity.opps.map(dem => dem.denominator)}
                      topStrengthTotal={1}
                      topOppTotal={1}
                    />
                  ))}
            </div>
          </div>
        </StrengthsContainer>
      ) : null}
    </>
  )
}
export default TopComponent

import { UserAlertType } from 'model'

export const USER_ALERT: UserAlertType = {
  title: 'Notice: Employee Survey Service Ending March 15th',
  message:
    'Effective March 15, 2024, Employee Surveys will be discontinued on our platform.'
}

export const USER_ALERT_DISABLED: UserAlertType = {
  title:
    'Notice: As of March 15, 2024, Employee Surveys Have Been Discontinued',
  message: ''
}

import React, { useState, useContext } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { Color } from '@mathison-inc/components'
import Filter from 'components/Filter'
import { RepresentationData } from 'model'
import { SurveySummaryContext } from 'context/SurveySummary'
import BubbleChart from './BubbleChart'

const EmployeeRepresentation = () => {
  const { representationSummary } = useContext(SurveySummaryContext)
  const [departmentFilter, setDepartmentFilter] = useState('All departments')
  const [departmentId, setDepartmentId] = useState('all')
  const [roleFilter, setRoleFilter] = useState('All role levels')
  const [roleId, setRoleId] = useState('all')

  if (!representationSummary) return <></>
  const surveyData =
    representationSummary.survey_data[`${departmentId}_${roleId}`]

  const filteredSurveyData = surveyData
    ? surveyData.filter(
        (group: any) => group.display && group.percentage_value > 0
      )
    : []

  const canConstructGraph: boolean = filteredSurveyData.length > 0

  return (
    <Box sx={{ width: '100%' }}>
      <Typography
        variant='h4'
        sx={{
          marginBottom: '24px',
          marginTop: '8px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        Employee Representation
      </Typography>

      <Grid
        container
        sx={{
          backgroundColor: Color.white,
          width: '100%',
          border: `1px solid ${Color.grey300}`,
          borderRadius: '8px',
          padding: '32px'
        }}
      >
        <Grid item xs={12} sx={{ marginBottom: '40px', display: 'flex' }}>
          <Filter
            name='By Department'
            filterValues={representationSummary.filters.departments}
            selection={departmentFilter}
            setSelection={setDepartmentFilter}
            setId={setDepartmentId}
            type='department'
          />
          <Filter
            name='Role Level'
            filterValues={representationSummary.filters.role_levels}
            selection={roleFilter}
            setSelection={setRoleFilter}
            setId={setRoleId}
            type='role'
          />
        </Grid>
        {canConstructGraph ? (
          filteredSurveyData.map((community: RepresentationData) => (
            <Grid item key={community.group_name} lg={3} md={4} sm={6} xs={12}>
              <BubbleChart
                community={community}
                largestCommunityPercentage={surveyData[0].percentage_value}
              />
            </Grid>
          ))
        ) : (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '120px 0'
            }}
          >
            <Typography variant='h5'>No data available</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default EmployeeRepresentation

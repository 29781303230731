import React from 'react'
import { Paper } from '@mathison-inc/components'
import { Button, Divider, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import HorizontalBarChart from 'components/Graphics/HorizaontalBarChart'
import { MyTab, TotalChartData } from './constants'

interface Props {
  selectedTab: MyTab
}
const ResponseContainer = ({ selectedTab }: Props) => {
  const navigate = useNavigate()

  const chartData = TotalChartData.filter(
    data => data.category === selectedTab.id
  )
  return (
    <Paper sx={{ marginTop: '16px' }}>
      <HorizontalBarChart chartData={chartData} selectedTab={selectedTab} />
      <Divider variant='fullWidth' sx={{ marginTop: '24px' }} />
      <Grid container padding='32px 32px 8px 32px'>
        <Typography variant='h5' lineHeight='44px' marginRight='24px'>
          Looking for opportunities to take action?
        </Typography>
        <Button
          variant='contained'
          color='secondary'
          onClick={() => {
            navigate('/action-plan/recommendations')
          }}
        >
          View recommendations
        </Button>
        <Typography
          variant='body16'
          lineHeight='44px'
          marginLeft='28px'
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => {
            navigate('/action-plan')
          }}
        >
          View goals
        </Typography>
      </Grid>
    </Paper>
  )
}

export default ResponseContainer

import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'

interface MyTab {
  id: string
  label: string
  link: string
  color: string
  gradientColor: string
}
interface Props {
  chartData: any[]
  selectedTab: MyTab
}

const HorizontalBarChart = ({ chartData, selectedTab }: Props) => {
  const svgRef: any = useRef()
  const tableHeight = chartData.reduce((accumulator: any, item: any) => {
    let currentRowHeight = 52
    if (item.label.length > 50) {
      currentRowHeight = 32 + 20 * 2
    }
    if (item.label.length > 100) {
      currentRowHeight = 32 + 20 * 3
    }
    if (item.label.length > 130) {
      currentRowHeight = 32 + 20 * 4
    }
    return accumulator + currentRowHeight
  }, 0)
  useEffect(() => {
    d3.select(svgRef.current).selectAll('g').remove()
    const svg = d3
      .select(svgRef.current)
      .append('svg')
      .attr('width', 1200)
      .attr('height', 900)
      .append('g')
      .attr('transform', `translate(30,0)`)
    const TopStartPoint = 50
    const LeftStartPoint = -30

    const addTopLabel = () => {
      svg
        .append('foreignObject')
        .attr('width', 1070)
        .attr('height', 100)
        .append('xhtml:div')
        .style('width', '180px')
        .style('letter-spacing', '0.17em')
        .style('margin-left', 'auto')
        .style('font-family', 'IBM Plex Sans')
        .style('font-weight', '700')
        .style('font-size', '12px')
        .style('text-align', 'center')
        .style('color', '#050B0E')
        .style('text-transform', 'uppercase')
        .html(`% of Employees that answered yes`)
    }
    addTopLabel()

    const addTopLine = () => {
      svg
        .append('line')
        .style('stroke', '#EDEDED')
        .style('stroke-width', 1)
        .attr('x1', LeftStartPoint)
        .attr('y1', TopStartPoint)
        .attr('x2', 1100)
        .attr('y2', TopStartPoint)
    }
    addTopLine()

    const Columns = [1, 2, 3, 4, 5]
    Columns.forEach((item: any, index) => {
      svg
        .append('line')
        .style('stroke', '#EDEDED')
        .style('stroke-width', 1)
        .style('z-index', '0')
        .attr('x1', (index + 1) * 160 + 160)
        .attr('y1', TopStartPoint)
        .attr('x2', (index + 1) * 160 + 160)
        .attr('y2', tableHeight + 10 + TopStartPoint)

      svg
        .append('text')
        .style('stroke', '#050B0E')
        .style('font-family', 'IBM Plex Sans')
        .style('font-size', '12px')
        .style('font-weight', '300')
        .style('letter-spacing', '0.17em')
        .attr('x', (index + 1) * 160 + 146)
        .attr('y', tableHeight + 40 + TopStartPoint)
        .text(`${25 * index}%`)
    })
    let rowHeight = 0
    let textHeight = 0

    chartData.forEach((item: any) => {
      let currentRowHeight = 52
      if (item.label.length > 50) {
        currentRowHeight = 32 + 20 * 2
      }
      if (item.label.length > 100) {
        currentRowHeight = 32 + 20 * 3
      }
      if (item.label.length > 130) {
        currentRowHeight = 32 + 20 * 4
      }

      rowHeight += currentRowHeight
      const addLine = () => {
        svg
          .append('line')
          .style('stroke', '#EDEDED')
          .style('stroke-width', 1)
          .attr('x1', LeftStartPoint)
          .attr('y1', rowHeight + TopStartPoint)
          .attr('x2', 1100)
          .attr('y2', rowHeight + TopStartPoint)
      }
      addLine()

      const addBar = () => {
        svg
          .append('foreignObject')
          .attr('width', (641 / 100) * item.value)
          .attr('height', 32)
          .style('background', selectedTab.gradientColor)
          .style('border-radius', '0px 6px 6px 0px')
          .attr('x', 320)
          .attr(
            'y',
            `${TopStartPoint + textHeight + (currentRowHeight - 32) / 2}px`
          )
      }
      addBar()

      const addText = () => {
        svg
          .append('foreignObject')
          .attr('width', 1100)
          .attr('height', 100)
          .attr('x', -20)
          .attr('y', `${TopStartPoint + textHeight + 16}px`)
          .append('xhtml:div')
          .style('width', '320px')
          .style('font-family', 'IBM Plex Sans')
          .style('font-weight', '400')
          .style('font-size', '14px')
          .style('text-align', 'right')
          .style('color', '#050B0E')
          .html(item.label)
        svg
          .append('foreignObject')
          .attr('width', 1100)
          .attr('height', 100)
          .attr('x', 800)
          .attr('y', `${TopStartPoint + textHeight + currentRowHeight / 3}px`)
          .append('xhtml:div')
          .style('width', '240px')
          .style('font-family', 'IBM Plex Sans')
          .style('font-weight', '700')
          .style('font-size', '18px')
          .style('text-align', 'right')
          .style('color', '#050B0E')
          .html(`${item.value}%`)
      }
      addText()

      textHeight = rowHeight
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData])
  return (
    <svg
      style={{
        marginLeft: '32px',
        marginTop: '30px',
        width: 1100,
        height: tableHeight + 100
      }}
      className='horizontal-bar-chart'
      ref={svgRef}
    />
  )
}

export default HorizontalBarChart

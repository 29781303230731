/* eslint-disable no-console */
import React from 'react'
import { DepartmentSummaryData } from 'model'
import { Box, Typography, BoxProps, styled } from '@mui/material'
import ResponseBar from './ResponseBar'
import BenchmarkBar from './BenchmarkBar'
import Percentages from './Percentages'

const ResponseWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: '#FFFFFF',
  marginBottom: '40px'
}))

interface IProps {
  averageResponseRate: number
  responseRateData: DepartmentSummaryData[]
  useDefaultGraph: boolean | undefined
}

const DepartmentResponses = ({
  averageResponseRate,
  responseRateData,
  useDefaultGraph
}: IProps) => {
  const departmentSummary: DepartmentSummaryData[] = responseRateData || []

  if (!useDefaultGraph) return null

  return (
    <ResponseWrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '45px 0 15px'
        }}
      >
        <Typography variant='body1' sx={{ fontWeight: 700, fontSize: '16px' }}>
          Top departments
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '5px 15px',
            backgroundColor: '#f7f7f7',
            borderRadius: '8px'
          }}
        >
          <Typography
            sx={{
              color: '#FFAD33',
              marginRight: '10px',
              fontSize: '36px',
              fontWeight: 700,
              lineHeight: '16px',
              marginBottom: '10px',
              fontFamily: 'IBM Plex Sans'
            }}
          >
            --
          </Typography>
          <Typography
            variant='body1'
            sx={{
              fontSize: '12px',
              fontWeight: '700',
              fontFamily: 'IBM Plex Sans'
            }}
          >
            ORG AVERAGE
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          '.MuiGrid-container:first-child': {
            borderTop: '1px solid rgba(0, 0, 0, 0.12)'
          }
        }}
      >
        {departmentSummary.map(department => (
          <ResponseBar
            key={department.dept_nano_id}
            department={department.dept_display_name}
            percentage={department.percentage_value}
          />
        ))}
      </Box>

      <Percentages departmentSummary={departmentSummary} />
      <BenchmarkBar
        numberOfDepartments={departmentSummary.length}
        benchmarkScore={(averageResponseRate / 100) * 66.66667 + 25}
      />
    </ResponseWrapper>
  )
}
export default DepartmentResponses

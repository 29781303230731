import React from 'react'
import { styled } from '@mui/material'
import { animated, easings, useSpring } from 'react-spring'

const BenchmarkWrapper = styled('div')(() => ({
  position: 'relative',
  width: '100%'
}))

const BenchmarkContainer = styled(animated.div)(
  ({ numberOfDepartments }: { numberOfDepartments: number }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: `${numberOfDepartments * 36}px`,
    background: 'transparent',
    borderRight: '4px dashed #FCA625',
    position: 'absolute',
    top: `-${numberOfDepartments * 36}px`,
    zIndex: 2
  })
)

interface IProps {
  benchmarkScore: number
  numberOfDepartments: number
}

const BenchmarkBar = ({ benchmarkScore, numberOfDepartments }: IProps) => {
  const barAnimation = useSpring({
    from: {
      width: '25%'
    },
    to: {
      width: `${Number(benchmarkScore)}%`
    },
    config: { duration: 2000, easing: easings.easeInOutQuart }
  })

  return (
    <BenchmarkWrapper>
      <BenchmarkContainer
        style={barAnimation}
        numberOfDepartments={numberOfDepartments}
      />
    </BenchmarkWrapper>
  )
}
export default BenchmarkBar

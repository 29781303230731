import React, { useEffect, useState, useRef } from 'react'
import { Box, Typography, Grid, GridProps, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Color } from 'components/SharedStyles'
import { useSpring, animated, easings } from 'react-spring'

interface OnboardingAdvancingContainerProps extends GridProps {
  showDivider: boolean
}

const OnboardingAdvancingContainer = styled(Grid, {
  shouldForwardProp: prop => prop !== 'showDivider'
})<OnboardingAdvancingContainerProps>(({ showDivider }) => ({
  position: 'relative',
  width: '100%',
  borderRight: showDivider ? `1px solid ${Color.grey300}` : 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 20px',
  boxSizing: 'border-box'
}))

const OrganizationAvgTextContainer = styled(Box)<BoxProps>(() => ({
  position: 'absolute',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '150px',
  top: '22%',
  textAlign: 'center'
}))

const StyledSvg = styled('svg')(() => ({
  height: '195px',
  width: '195px',
  marginBottom: '24px'
}))

const StyledCircle = styled('circle')(() => ({
  strokeLinecap: 'round',
  fill: 'none',
  strokeWidth: '12px',
  transform: 'rotate(270deg)'
}))

interface Props {
  displayText: string
  score: number
  index: number
}

const colors = [
  { foreground: '#9AE5F3', background: '#D3F8FF' },
  { foreground: '#C3D12C', background: '#EBF2A1' },
  { foreground: '#FCA625', background: '#FFE0B1' }
]

const OnboardingAdvancingCircleGraph = ({
  displayText,
  score,
  index
}: Props) => {
  const showDivider = index < 2
  const [isVisible, setIsVisible] = useState(false)
  const myRef = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    if (isVisible) return
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0]
      setIsVisible(entry.isIntersecting)
    })
    observer.observe(myRef.current)
  }, [setIsVisible, isVisible])

  const [foregroundCircleAnimation, styleHandler] = useSpring(() => ({
    strokeDashoffset: 560
  }))

  const [percent, percentHandler] = useSpring(() => ({
    percent: 0
  }))

  useEffect(() => {
    if (isVisible) {
      styleHandler.start({
        strokeDashoffset: 560 - 5.6 * score,
        config: { duration: 2000, easing: easings.easeInOutQuart }
      })
      percentHandler.start({
        percent: score,
        config: { duration: 2000, easing: easings.easeInOutQuart }
      })
    }
  }, [isVisible, score])

  return (
    <OnboardingAdvancingContainer
      showDivider={showDivider}
      item
      xs={4}
      ref={myRef}
    >
      <StyledSvg>
        <StyledCircle
          r='90'
          cx='-97'
          cy='97'
          style={{
            strokeWidth: '8px',
            stroke: colors[index].background,
            strokeDasharray: 560,
            strokeDashoffset: 0
          }}
        />
        <animated.circle
          r='90'
          cx='-97'
          cy='97'
          style={{
            strokeLinecap: 'round',
            fill: 'none',
            transform: 'rotate(270deg)',
            strokeWidth: '12px',
            stroke: colors[index].foreground,
            strokeDasharray: 560,
            ...foregroundCircleAnimation
          }}
        />
      </StyledSvg>
      <OrganizationAvgTextContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <animated.h1
            style={{
              margin: 0,
              lineHeight: '36px',
              fontSize: '36px'
            }}
          >
            {percent.percent.to(percentage => percentage.toFixed(0))}
          </animated.h1>
          <Typography variant='h1'>%</Typography>
        </Box>
      </OrganizationAvgTextContainer>
      <Typography variant='h6' sx={{ fontWeight: 400, width: '90%' }}>
        {displayText}
      </Typography>
    </OnboardingAdvancingContainer>
  )
}

export default OnboardingAdvancingCircleGraph

import React from 'react'
import { styled } from '@mui/material'

const PercentBarWrapper = styled('div')(() => ({
  position: 'relative',
  width: '100%'
}))

const PercentBarContainer = styled('div')(
  ({ percent, dataLength }: { percent: number; dataLength: number }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: `${dataLength * 91}px`,
    background: 'transparent',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    position: 'absolute',
    top: `-${dataLength * 91}px`,
    zIndex: 0,
    width: `${Number(percent)}%`
  })
)

interface IProps {
  percent: number
  dataLength: number
}

const PercentBar = ({ percent, dataLength }: IProps) => {
  return (
    <PercentBarWrapper>
      <PercentBarContainer percent={percent} dataLength={dataLength} />
    </PercentBarWrapper>
  )
}
export default PercentBar

import { Color } from '@mathison-inc/components'

export interface MyTab {
  id: string
  label: string
  link: string
  backgroundColor: string
  gradientColor: string
  color: string
}
export const MyTabs: MyTab[] = [
  {
    id: 'D&T',
    label: 'Defining & Tracking',
    link: '/equity-index/employee-survey/equitable-behaviors/defining-tracking',
    gradientColor: 'linear-gradient(270deg, #DD66AD 50%, #B31E74 100%)',
    backgroundColor: Color.raspberry100,
    color: Color.raspberry500
  },
  {
    id: 'A&S',
    label: 'Attracting & Sourcing',
    link: '/equity-index/employee-survey/equitable-behaviors/attracting-sourcing',
    gradientColor: 'linear-gradient(270.04deg, #430DA8 50.04%, #956BDB 94.1%)',
    backgroundColor: Color.eggplant100,
    color: Color.eggplant500
  },
  {
    id: 'I&E',
    label: 'Interviewing & Engaging',
    link: '/equity-index/employee-survey/equitable-behaviors/interviewing-engaging',
    gradientColor: 'linear-gradient(270.03deg, #068D8E 50.03%, #6AC2C4 86.68%)',
    backgroundColor: Color.turquoise100,
    color: Color.turquoise500
  },
  {
    id: 'O&A',
    label: 'Onboarding & Advancing',
    link: '/equity-index/employee-survey/equitable-behaviors/onboarding-advancing',
    gradientColor: 'linear-gradient(270.03deg, #375FC6 50.03%, #829FE9 92.29%)',
    backgroundColor: Color.iris100,
    color: Color.iris500
  }
]

export const ChartData = [
  {
    label:
      'Does your organization have a statement that defines its commitment to diversity, equity, and inclusion (DEI)?',
    value: '30'
  },
  {
    label:
      'Do you know which specific under-represented communities your organization hopes to hire and advance?',
    value: '35'
  },
  {
    label: "Are you familiar with your organization's DEI goals?",
    value: '70'
  },
  {
    label:
      'Do you feel responsible for helping the organization achieve its diversity goals?',
    value: '18'
  },
  {
    label:
      'Are you held accountable for helping the organization achieve its diversity goals?',
    value: '60'
  }
]

export const TotalChartData = [
  {
    category: 'D&T',
    label: "Are you familiar with your organization's DEI goals?",
    value: '87'
  },
  {
    category: 'D&T',
    label:
      "Are you familiar with your organization's employee diversity data reports?",
    value: '51'
  },
  {
    category: 'D&T',
    label:
      'Does your organization have a statement that defines its commitment to diversity, equity, and inclusion (DEI)?',
    value: '50'
  },
  {
    category: 'D&T',
    label:
      'Do you feel responsible for helping the organization achieve its diversity goals?',
    value: '48'
  },
  {
    category: 'D&T',
    label:
      'Are you held accountable for helping the organization achieve its diversity goals?',
    value: '25'
  },
  {
    category: 'A&S',
    label:
      'Do you include salary ranges on all job postings or include them if they are legally required?',
    value: '66'
  },
  {
    category: 'A&S',
    label:
      "Are you familiar with your organization's internal-facing job postings?",
    value: '64'
  },
  {
    category: 'A&S',
    label:
      'Do you intentionally write job descriptions to ensure they are inclusive?',
    value: '52'
  },
  {
    category: 'A&S',
    label:
      'Do you ensure diverse candidate representation throughout the hiring process?',
    value: '31'
  },
  {
    category: 'A&S',
    label:
      "Do you see people like you represented on your organization's website and social media?",
    value: '19'
  },
  {
    category: 'I&E',
    label:
      'Do you submit interview feedback independently or before reviewing the feedback of others?',
    value: '54'
  },
  {
    category: 'I&E',
    label:
      'Have you completed training related to inclusive hiring and interview practices within the last year?',
    value: '49'
  },
  {
    category: 'I&E',
    label:
      'As part of the interview process, do all interview team members meet ahead of the first interview to get aligned around their role, critical skills for the position, and the overall process?',
    value: '44'
  },
  {
    category: 'I&E',
    label:
      'As part of the interview process, do you use a standardized interview scorecard with specific questions and evaluation criteria?',
    value: '35'
  },
  {
    category: 'I&E',
    label:
      'Do you follow a structured process when interviewing candidates, such as defined interview states, key stakeholders, and consistent questions?',
    value: '22'
  },
  {
    category: 'O&A',
    label:
      'Have you been recognized for your contributions at work in the past 3 months?',
    value: '76'
  },
  {
    category: 'O&A',
    label:
      'Have you received training or resources to help you understand biases and the impact of microaggressions within the last year?',
    value: '52'
  },
  {
    category: 'O&A',
    label:
      'Does your organization have any affinity groups that are relevant to you? (e.g. Employee Resource Group or Business Resource Group)',
    value: '30'
  },
  {
    category: 'O&A',
    label: 'Have you had a mentor at work in the last year?',
    value: '27'
  },
  {
    category: 'O&A',
    label:
      'As part of your onboarding process, were you provided a personalized onboarding plan?',
    value: '18'
  }
]

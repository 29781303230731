/* eslint-disable no-unsafe-optional-chaining */
import React, { useContext, useEffect, useState, useRef } from 'react'
import { Box, Typography, Grid, GridProps, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Color, theme } from 'components/SharedStyles'
import { useSpring, animated, easings } from 'react-spring'
import { SurveySummaryContext } from 'context/SurveySummary'

const StyledSvg = styled('svg')(() => ({
  height: '260px',
  width: '260px'
}))

const StyledCircle = styled('circle')(() => ({
  strokeLinecap: 'round',
  fill: 'none',
  strokeWidth: '12px',
  transform: 'rotate(270deg)'
}))

const InclusionBelongingContainer = styled(Grid)<GridProps>(() => ({
  position: 'relative',
  width: '100%',
  padding: '32px 0 32px',
  border: `1px solid ${Color.grey300}`,
  borderRadius: '8px',
  backgroundColor: '#FFFFFF'
}))

const InclusionBelongingGraphContainer = styled(Grid)<GridProps>(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: 'none',
  [theme.breakpoints.down('lg')]: {
    paddingBottom: '40px',
    paddingTop: '20px',
    borderBottom: `1px solid ${Color.grey300}`
  }
}))

const OrganizationAvgTextContainer = styled(Box)<BoxProps>(() => ({
  position: 'absolute',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '150px',
  top: '42%',
  [theme.breakpoints.down('lg')]: {
    top: '19%'
  }
}))

const TopStrengthOpportunityContainer = styled(Grid)<GridProps>(() => ({
  borderLeft: `1px solid ${Color.grey300}`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    borderLeft: 'none',
    paddingTop: '20px'
  }
}))

const InclusionBelongingGraph = () => {
  const { inclusionBelongingData } = useContext(SurveySummaryContext)
  const [isVisible, setIsVisible] = useState(false)
  const myRef = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    if (
      isVisible ||
      !inclusionBelongingData ||
      !inclusionBelongingData.organization_avg
    )
      return
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0]
      setIsVisible(entry.isIntersecting)
    })
    observer.observe(myRef.current)
  }, [setIsVisible, isVisible, inclusionBelongingData])

  const [foregroundCircleAnimation, styleHandler] = useSpring(() => ({
    strokeDashoffset: 750
  }))

  const [score, scoreHandler] = useSpring(() => ({
    score: 0
  }))

  useEffect(() => {
    if (isVisible) {
      styleHandler.start({
        strokeDashoffset: 750 - 150 * inclusionBelongingData?.organization_avg,
        config: { duration: 2000, easing: easings.easeInOutQuart }
      })
      scoreHandler.start({
        score: inclusionBelongingData?.organization_avg,
        config: { duration: 2000, easing: easings.easeInOutQuart }
      })
    }
  }, [
    isVisible,
    inclusionBelongingData?.organization_avg,
    styleHandler,
    scoreHandler
  ])
  if (!inclusionBelongingData || !inclusionBelongingData?.organization_avg) {
    return <></>
  }

  return (
    <>
      <Typography
        variant='h4'
        sx={{
          marginBottom: '24px',
          marginTop: '8px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        Inclusion and Belonging
      </Typography>
      <InclusionBelongingContainer container ref={myRef}>
        <InclusionBelongingGraphContainer item md={12} lg={5}>
          <StyledSvg>
            <StyledCircle
              r='120'
              cx='-130'
              cy='130'
              style={{
                strokeWidth: '8px',
                stroke: '#DADFFF',
                strokeDasharray: 750,
                strokeDashoffset: 0
              }}
            />
            <animated.circle
              r='120'
              cx='-130'
              cy='130'
              style={{
                fill: 'none',
                transform: 'rotate(-90deg)',
                strokeWidth: '12px',
                stroke: '#3148D3',
                strokeDasharray: 750,
                strokeLinecap: 'round',
                ...foregroundCircleAnimation
              }}
            />
          </StyledSvg>
          <OrganizationAvgTextContainer>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginBottom: '8px'
              }}
            >
              <animated.h1 style={{ margin: 0, lineHeight: '32px' }}>
                {score.score.to(scorePosition => scorePosition.toFixed(1))}
              </animated.h1>
              <Typography
                variant='h6'
                sx={{ fontWeight: 400, marginLeft: '6px' }}
              >
                /5
              </Typography>
            </Box>
            <Typography
              variant='h6'
              sx={{
                textTransform: 'uppercase',
                fontSize: '12px',
                letterSpacing: '0.17em',
                textAlign: 'center'
              }}
            >
              Organization Avg
            </Typography>
          </OrganizationAvgTextContainer>
        </InclusionBelongingGraphContainer>
        <TopStrengthOpportunityContainer item md={12} lg={7}>
          <Box
            sx={{
              borderBottom: `1px solid ${Color.grey300}`,
              padding: '32px 0 32px',
              width: '75%'
            }}
          >
            <Typography variant='h6' sx={{ marginBottom: '24px' }}>
              Top Strength
            </Typography>
            <Typography variant='h3' sx={{ fontWeight: 400 }}>
              {inclusionBelongingData.top_strength.display_text}{' '}
              <b>{inclusionBelongingData.top_strength.score}</b>
              {' /5'}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '75%',
              padding: '32px 0 32px'
            }}
          >
            <Typography variant='h6' sx={{ marginBottom: '24px' }}>
              Top Opportunity
            </Typography>
            <Typography variant='h3' sx={{ fontWeight: 400 }}>
              {inclusionBelongingData.top_opportunity.display_text}{' '}
              <b>{inclusionBelongingData.top_opportunity.score}</b>
              {' /5'}
            </Typography>
          </Box>
        </TopStrengthOpportunityContainer>
      </InclusionBelongingContainer>
    </>
  )
}

export default InclusionBelongingGraph

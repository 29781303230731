import React from 'react'
import {
  Box,
  Grid,
  GridProps,
  Tooltip,
  Typography,
  styled
} from '@mui/material'
import { animated, easings, useSpring } from 'react-spring'
import { theme } from '@mathison-inc/components'

const MainContainer = styled(Grid)<GridProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
}))

const GraphBarContainer = styled(animated.div)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  height: '30px',
  background: 'linear-gradient(270deg, #5F71E5 50%, #9DA7E8 99.61%)',
  borderTopRightRadius: '15px',
  borderBottomRightRadius: '15px',
  position: 'relative',
  zIndex: 1
}))

const PercentContainer = styled(Grid)<GridProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'right',
  padding: '5px 10px'
}))

interface IProps {
  question: string
  percentage: number
}

const GraphBar = ({ question, percentage }: IProps) => {
  // Normalize the current score to a percentage based on the max width of the Foreground
  const responsePercentage = percentage
  const addBoldItalic = (match: string) => {
    return `<b><i>${match}</i></b>`
  }
  const barAnimation = useSpring({
    from: {
      width: '0%'
    },
    to: {
      width: `${Number(responsePercentage)}%`
    },
    config: { duration: 2000, easing: easings.easeInOutQuart }
  })

  return (
    <MainContainer container>
      <Grid sx={{ padding: '12px 10px', position: 'relative' }} item xs={3}>
        <Box
          sx={{
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            position: 'relative',
            zIndex: 3,
            height: '65px'
          }}
        >
          <Tooltip title={question} arrow placement='left'>
            <Typography
              variant='body1'
              sx={{
                fontSize: '14px',
                height: 'inherit'
              }}
              dangerouslySetInnerHTML={{
                __html: `% ${question.replaceAll(
                  /familiar|responsible|accountable/gi,
                  addBoldItalic
                )}`
              }}
            />
          </Tooltip>
        </Box>
      </Grid>
      <Grid
        item
        xs={8}
        sx={{
          padding: '30px 0',
          borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
          borderRight: '1px solid rgba(0, 0, 0, 0.12)'
        }}
      >
        <GraphBarContainer style={barAnimation} />
      </Grid>
      <PercentContainer item xs={1}>
        <Typography
          variant='h6'
          sx={{
            fontSize: '18px',
            letterSpacing: '0.01em',
            [theme.breakpoints.down(1000)]: {
              marginRight: '-10px'
            }
          }}
        >
          {percentage}%
        </Typography>
      </PercentContainer>
    </MainContainer>
  )
}
export default GraphBar

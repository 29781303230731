import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  // IconButton,
  Tab,
  Typography
} from '@mui/material'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { MyTabs, MyTab } from './constants'
import { StyledTabs } from './components'
import TopComponent from './TopComponent'
import { strengths, opportunities } from './TopComponent/constants'
import ResponseContainer from './ResponseContainer'

const EquitableBehaviors = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { tab } = useParams()

  const { pathname } = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    document.title = 'Equitable Behaviors - Employee Survey | Mathison'
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
    if (pathname === '/equity-index/employee-survey/equitable-behaviors') {
      navigate(MyTabs[0].link)
    }
    return () => {
      document.title = 'Mathison'
    }
  }, [pathname, navigate])

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    tab ? MyTabs.findIndex((myTab: MyTab) => myTab.link.endsWith(tab)) : 0
  )

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    navigate(MyTabs[newValue].link)
    setSelectedTabIndex(newValue)
  }
  const selectedTab = MyTabs[selectedTabIndex]
  return (
    <>
      <Typography variant='h1' sx={{ marginBottom: '40px' }}>
        Equitable Behaviors
      </Typography>
      {isLoading ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress
            size={60}
            sx={{ margin: 'auto', marginTop: '32px' }}
          />
        </Box>
      ) : (
        <>
          <StyledTabs
            value={selectedTabIndex}
            sx={{ width: '880px' }}
            onChange={handleChange}
          >
            {MyTabs.map(item => (
              <Tab label={item.label} />
            ))}
          </StyledTabs>
          <div style={{ marginTop: 40 }}>
            <Typography variant='h5'>
              Top Strengths & Opportunities:{' '}
              <Typography variant='h5Light'>{selectedTab.label}</Typography>
              {/* <IconButton color='primary'>
                <Info />
              </IconButton> */}
            </Typography>
            <TopComponent
              edgeStrength={strengths}
              edgeOpp={opportunities}
              selectedTabIndex={selectedTabIndex}
              selectedMainTab={selectedTab}
            />
          </div>
          <Typography variant='h5' marginTop='40px'>
            Responses:{' '}
            <Typography variant='h5Light'>{selectedTab.label}</Typography>
          </Typography>
          <ResponseContainer selectedTab={selectedTab} />
        </>
      )}
    </>
  )
}

export default EquitableBehaviors

import React, { useContext, useEffect } from 'react'
import { Paper } from '@mathison-inc/components'
import { Typography, CardContent, Grid, Divider, Button } from '@mui/material'
import { MicroServiceContext } from 'context/MicroService'
import UserAlert from 'components/UserAlert'
import { USER_ALERT } from '../constants'
import Block from './Block'

const EmptyPage = () => {
  const { displayNotification, currentAccount } =
    useContext(MicroServiceContext)

  useEffect(() => {
    document.title = 'Employee Survey | Mathison'
    return () => {
      document.title = 'Mathison'
    }
  }, [])

  const onClick = () => {
    displayNotification(
      'Your Customer Impact manager will be in touch shortly!'
    )

    fetch(process.env.REACT_APP_ZAPIER_URL as string, {
      method: 'POST',
      body: JSON.stringify({
        id: currentAccount.id.split('/')[0],
        name: currentAccount.user.name,
        employerName: currentAccount.employer.name
      })
    })
  }
  return (
    <>
      <UserAlert userAlert={USER_ALERT} hoursToHide={2} />
      <Typography variant='h1' sx={{ marginBottom: '24px' }}>
        Mathison employee survey
      </Typography>
      <Paper>
        <CardContent sx={{ padding: '58px 104px' }}>
          <Grid container>
            <Grid item xs={5}>
              <Typography variant='h4' component='p' marginTop='24px'>
                Our powerful new tool gives you a complete view into your
                organization&apos;s DEI efforts
              </Typography>
              <Typography variant='body1' component='p' marginTop='24px'>
                Easily gauge how your DEI goals are being adopted across your
                entire organization. Get precise feedback on where to focus your
                initiatives and make the most progress, get granular views by
                department and community groups. Get a full 360º view across the
                hiring and employee journey.
              </Typography>
              <Typography
                variant='body16'
                component='p'
                marginTop='24px'
                marginBottom='26px'
              >
                Best of all, the Employee Survey is fully customizable and
                included in your subscription.
              </Typography>
            </Grid>
            <Grid xs={2} />
            <Grid xs marginBottom='44px'>
              <img src='/images/bumper/360image.jpg' alt='360image' />
            </Grid>
          </Grid>
          <Divider />
          <Grid container marginTop='36px' marginBottom='52px'>
            <Block
              title='Employee Representation'
              content={
                "Understand your organization's diversity by department and job level using Mathison's expanded categories of underrepresented communities."
              }
              imageUrl='/images/bumper/circles.jpg'
            />
            <Grid xs={1} />
            <Block
              title='Inclusion & Belonging'
              content='Accurately gauge how included and understood your employees feel at work to better focus retention and engagement efforts.'
              imageUrl='/images/bumper/progress.jpg'
            />
            <Grid xs={1} />
            <Block
              title='Equitable Behavior'
              content='See how your DEI initiatives are being embraced by your organization at the most critical points in the hiring and employee journey.'
              imageUrl='/images/bumper/numbers.jpg'
            />
          </Grid>
          {/* <Divider />
          <Grid container marginTop='48px'>
            <Grid item xs={8}>
              <Typography variant='h5'>
                Ready to get the full picture?
              </Typography>
            </Grid>
            <Grid xs={4} marginBottom='24px'>
              <Button
                variant='contained'
                color='secondary'
                sx={{ float: 'right' }}
                onClick={onClick}
              >
                Learn more
              </Button>
              <Button
                variant='contained'
                sx={{ float: 'right', right: '18px' }}
                onClick={onClick}
              >
                Schedule a survey
              </Button>
            </Grid>
          </Grid> */}
        </CardContent>
      </Paper>
    </>
  )
}
export default EmptyPage

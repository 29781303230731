import { useQuery } from '@apollo/client'
import { GET_EMPLOYEE_SURVEY_SUMMARY } from './gql'

const useEmployeeSurvey = () => {
  const { data, loading } = useQuery(GET_EMPLOYEE_SURVEY_SUMMARY)

  const employerSurveySummary = data?.employerSurveySummary

  return { employerSurveySummary, isLoading: loading }
}

export default useEmployeeSurvey

export const INITIAL_TOP_THREE_STRENGTHS = [
  { display_text: '', score: 0 },
  { display_text: '', score: 0 },
  { display_text: '', score: 0 }
]

export const INITIAL_SURVEY_COUNT = {
  total_sent: 0,
  responded_percentage: 0,
  completed_percentage: 0,
  responded_count: 0,
  completed_count: 0
}

export const INITIAL_DEPARTMENT_REPRESENTATION = [
  {
    dept_nano_id: '',
    dept_display_name: '',
    response_count: 0,
    total_count: 0,
    percentage_value: 0
  }
]

export const DEMO_SURVEY_COUNT = {
  completed_percentage: 88.8,
  responded_percentage: 92.4,
  total_sent: 278,
  responded_count: null,
  completed_count: null
}

export const DEMO_RESPONSE_RATE_DATA = [
  {
    dept_display_name: 'Administration',
    dept_nano_id: '10210',
    percentage_value: 100,
    response_count: 2,
    total_count: 1
  },
  {
    dept_display_name: 'Legal',
    dept_nano_id: '10213',
    percentage_value: 99,
    response_count: 3,
    total_count: 3
  },
  {
    dept_display_name: 'Program Management',
    dept_nano_id: '10216',
    percentage_value: 97.5,
    response_count: 4,
    total_count: 4
  },
  {
    dept_display_name: 'Strategy',
    dept_nano_id: '10218',
    percentage_value: 97.1,
    response_count: 2,
    total_count: 2
  },
  {
    dept_display_name: 'Commercial',
    dept_nano_id: '10211',
    percentage_value: 96.2,
    response_count: 24,
    total_count: 25
  },
  {
    dept_display_name: 'People',
    dept_nano_id: '10215',
    percentage_value: 90.9,
    response_count: 30,
    total_count: 33
  },
  {
    dept_display_name: 'Other',
    dept_nano_id: '10220',
    percentage_value: 88.7,
    response_count: 5,
    total_count: 5
  },
  {
    dept_display_name: 'Finance',
    dept_nano_id: '10212',
    percentage_value: 87.1,
    response_count: 15,
    total_count: 14
  },
  {
    dept_display_name: 'Science',
    dept_nano_id: '10217',
    percentage_value: 85.7,
    response_count: 12,
    total_count: 14
  },
  {
    dept_display_name: 'Technology',
    dept_nano_id: '10219',
    percentage_value: 84.8,
    response_count: 67,
    total_count: 79
  },
  {
    dept_display_name: 'Operations',
    dept_nano_id: '10214',
    percentage_value: 84.7,
    response_count: 83,
    total_count: 98
  }
]

export const DEMO_REPRESENTATION_SUMMARY = {
  filters: {
    departments: [
      {
        dept_name: 'All departments',
        dept_nano_id: 'all'
      },
      {
        dept_name: 'Administration',
        dept_nano_id: '10210'
      },
      {
        dept_name: 'Commercial',
        dept_nano_id: '10211'
      },
      {
        dept_name: 'Finance',
        dept_nano_id: '10212'
      },
      {
        dept_name: 'Legal',
        dept_nano_id: '10213'
      },
      {
        dept_name: 'Operations',
        dept_nano_id: '10214'
      },
      {
        dept_name: 'People',
        dept_nano_id: '10215'
      },
      {
        dept_name: 'Program Management',
        dept_nano_id: '10216'
      },
      {
        dept_name: 'Science',
        dept_nano_id: '10217'
      },
      {
        dept_name: 'Strategy',
        dept_nano_id: '10218'
      },
      {
        dept_name: 'Technology',
        dept_nano_id: '10219'
      },
      {
        dept_name: 'Other',
        dept_nano_id: '10220'
      }
    ],
    role_levels: [
      {
        role_level: 'All role levels',
        role_level_nano_id: 'all'
      },
      {
        role_level: '5-8',
        role_level_nano_id: '10712'
      },
      {
        role_level: '3-4',
        role_level_nano_id: '10711'
      },
      {
        role_level: '1-2',
        role_level_nano_id: '10710'
      },
      {
        role_level: '9-12',
        role_level_nano_id: '10713'
      },
      {
        role_level: '13',
        role_level_nano_id: '10714'
      }
    ]
  },
  survey_data: {
    '10210_10710': [
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 100,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 1
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 100,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 1
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 100,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 1
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 100,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 1
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 100,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 1
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 1
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 1
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 1
      }
    ],
    '10210_10711': [
      {
        display: false,
        group_name: '60 or older',
        nano_id: '10814',
        percentage_value: 100,
        response_count: 1,
        special_count: 1,
        topic: 'age',
        total_count: 1
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 100,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 1
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 100,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 1
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 100,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 1
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 100,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 1
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 100,
        response_count: 1,
        topic: '',
        total_count: 1
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 1
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 1
      }
    ],
    '10210_all': [
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 200,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 1
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 200,
        response_count: 2,
        special_count: 0,
        topic: 'gender',
        total_count: 1
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 200,
        response_count: 2,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 1
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 200,
        response_count: 2,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 1
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 100,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 1
      },
      {
        display: false,
        group_name: '60 or older',
        nano_id: '10814',
        percentage_value: 100,
        response_count: 1,
        special_count: 1,
        topic: 'age',
        total_count: 1
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 100,
        response_count: 1,
        topic: '',
        total_count: 1
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 1
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 1
      }
    ],
    '10211_10710': [
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 12,
        response_count: 3,
        special_count: 0,
        topic: 'age',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 12,
        response_count: 3,
        special_count: 0,
        topic: 'gender',
        total_count: 25
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 12,
        response_count: 3,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 25
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 12,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 25
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 12,
        response_count: 3,
        special_count: 0,
        topic: 'community',
        total_count: 25
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 25
      }
    ],
    '10211_10711': [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 24,
        response_count: 6,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 25
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 24,
        response_count: 6,
        special_count: 0,
        topic: 'community',
        total_count: 25
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 20,
        response_count: 5,
        special_count: 0,
        topic: 'age',
        total_count: 25
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 16,
        response_count: 4,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 25
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 12,
        response_count: 3,
        special_count: 0,
        topic: 'gender',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 12,
        response_count: 3,
        special_count: 0,
        topic: 'gender',
        total_count: 25
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 8,
        response_count: 2,
        special_count: 2,
        topic: 'ethnicity',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 8,
        response_count: 2,
        topic: '',
        total_count: 25
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 4,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 4,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 4,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 25
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 25
      }
    ],
    '10211_10712': [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 52,
        response_count: 13,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 25
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 48,
        response_count: 12,
        special_count: 0,
        topic: 'age',
        total_count: 25
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 36,
        response_count: 9,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 28,
        response_count: 7,
        special_count: 0,
        topic: 'gender',
        total_count: 25
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 24,
        response_count: 6,
        special_count: 0,
        topic: 'gender',
        total_count: 25
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 20,
        response_count: 5,
        special_count: 0,
        topic: 'community',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 20,
        response_count: 5,
        special_count: 0,
        topic: 'community',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 12,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 12,
        response_count: 3,
        special_count: 0,
        topic: 'community',
        total_count: 25
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 4,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 25
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 4,
        response_count: 1,
        special_count: 1,
        topic: 'ethnicity',
        total_count: 25
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 4,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 4,
        response_count: 1,
        topic: '',
        total_count: 25
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 25
      }
    ],
    '10211_10713': [
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 12,
        response_count: 3,
        special_count: 0,
        topic: 'gender',
        total_count: 25
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 12,
        response_count: 3,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 25
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 12,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 12,
        response_count: 3,
        special_count: 0,
        topic: 'community',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 12,
        response_count: 3,
        topic: '',
        total_count: 25
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 8,
        response_count: 2,
        special_count: 2,
        topic: 'age',
        total_count: 25
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 4,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 25
      },
      {
        display: false,
        group_name: '60 or older',
        nano_id: '10814',
        percentage_value: 4,
        response_count: 1,
        special_count: 1,
        topic: 'age',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 4,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 25
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11016',
        percentage_value: 4,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 4,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 25
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 4,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 25
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 25
      }
    ],
    '10211_all': [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 100,
        response_count: 25,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 25
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 76,
        response_count: 19,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 56,
        response_count: 14,
        special_count: 0,
        topic: 'gender',
        total_count: 25
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 56,
        response_count: 14,
        special_count: 0,
        topic: 'community',
        total_count: 25
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 52,
        response_count: 13,
        special_count: 0,
        topic: 'age',
        total_count: 25
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 48,
        response_count: 12,
        special_count: 0,
        topic: 'gender',
        total_count: 25
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 36,
        response_count: 9,
        special_count: 0,
        topic: 'age',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 32,
        response_count: 8,
        special_count: 0,
        topic: 'community',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 16,
        response_count: 4,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 25
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 12,
        response_count: 3,
        special_count: 3,
        topic: 'ethnicity',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 12,
        response_count: 3,
        special_count: 0,
        topic: 'community',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 12,
        response_count: 3,
        topic: '',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 12,
        response_count: 3,
        topic: '',
        total_count: 25
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 8,
        response_count: 2,
        special_count: 2,
        topic: 'age',
        total_count: 25
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 8,
        response_count: 2,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 25
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 8,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 25
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 4,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 25
      },
      {
        display: false,
        group_name: '60 or older',
        nano_id: '10814',
        percentage_value: 4,
        response_count: 1,
        special_count: 1,
        topic: 'age',
        total_count: 25
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11016',
        percentage_value: 4,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 25
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 25
      }
    ],
    '10212_10711': [
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 28.6,
        response_count: 4,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 14.3,
        response_count: 2,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 14.3,
        response_count: 2,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 14.3,
        response_count: 2,
        special_count: 2,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 14.3,
        response_count: 2,
        topic: '',
        total_count: 14
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Queer',
        nano_id: '11013',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 14
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 7.1,
        response_count: 1,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      }
    ],
    '10212_10712': [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 42.9,
        response_count: 6,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 14
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 42.9,
        response_count: 6,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 28.6,
        response_count: 4,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 14.3,
        response_count: 2,
        special_count: 2,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 14.3,
        response_count: 2,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 14.3,
        response_count: 2,
        topic: '',
        total_count: 14
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Gay',
        nano_id: '11012',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 7.1,
        response_count: 1,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      }
    ],
    '10212_10713': [
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 14
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 14.3,
        response_count: 2,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 1,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 7.1,
        response_count: 1,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      }
    ],
    '10212_10714': [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 14.3,
        response_count: 2,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 14
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 1,
        topic: 'age',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 7.1,
        response_count: 1,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      }
    ],
    '10212_all': [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 100,
        response_count: 14,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 14
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 100,
        response_count: 14,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 71.4,
        response_count: 10,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 42.9,
        response_count: 6,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 42.9,
        response_count: 6,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 35.7,
        response_count: 5,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 35.7,
        response_count: 5,
        special_count: 5,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 35.7,
        response_count: 5,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 35.7,
        response_count: 5,
        topic: '',
        total_count: 14
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 28.6,
        response_count: 4,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 28.6,
        response_count: 4,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 14.3,
        response_count: 2,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 14.3,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 14.3,
        response_count: 2,
        topic: '',
        total_count: 14
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 1,
        topic: 'age',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Queer',
        nano_id: '11013',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Gay',
        nano_id: '11012',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 7.1,
        response_count: 1,
        topic: '',
        total_count: 14
      }
    ],
    '10213_10711': [
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 3
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 3
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 3
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 3
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11216',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 3
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 3
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 3
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 3
      }
    ],
    '10213_10712': [
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 3
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 3
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 3
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 3
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 3
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 3
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 3
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 3
      }
    ],
    '10213_10713': [
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 3
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 3
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 3
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 3
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 3
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 3
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 3
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 3
      }
    ],
    '10213_all': [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 100,
        response_count: 3,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 3
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 66.7,
        response_count: 2,
        special_count: 0,
        topic: 'age',
        total_count: 3
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 66.7,
        response_count: 2,
        special_count: 0,
        topic: 'gender',
        total_count: 3
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 66.7,
        response_count: 2,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 3
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 3
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 3
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 3
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 3
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11216',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 3
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 33.3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 3
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 3
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 3
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 3
      }
    ],
    '10214_10710': [
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 8.2,
        response_count: 8,
        special_count: 0,
        topic: 'gender',
        total_count: 98
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 8.2,
        response_count: 8,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 7.1,
        response_count: 7,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 6.1,
        response_count: 6,
        special_count: 0,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 6.1,
        response_count: 6,
        special_count: 0,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 5.1,
        response_count: 5,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 4.1,
        response_count: 4,
        topic: '',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 3.1,
        response_count: 3,
        special_count: 0,
        topic: 'gender',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Gay',
        nano_id: '11012',
        percentage_value: 3.1,
        response_count: 3,
        special_count: 2,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 3.1,
        response_count: 3,
        special_count: 3,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 3.1,
        response_count: 3,
        topic: '',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 2,
        response_count: 2,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 2,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Non-binary',
        nano_id: '10912',
        percentage_value: 1,
        response_count: 1,
        special_count: 1,
        topic: 'gender',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Bisexual',
        nano_id: '11010',
        percentage_value: 1,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11016',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Queer',
        nano_id: '11013',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Asexual',
        nano_id: '11017',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11116',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11117',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11216',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 98
      }
    ],
    '10214_10711': [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 20.4,
        response_count: 20,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 18.4,
        response_count: 18,
        special_count: 0,
        topic: 'gender',
        total_count: 98
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 17.3,
        response_count: 17,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 13.3,
        response_count: 13,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 12.2,
        response_count: 12,
        special_count: 0,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 10.2,
        response_count: 10,
        special_count: 0,
        topic: 'age',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 8.2,
        response_count: 8,
        special_count: 0,
        topic: 'gender',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 5.1,
        response_count: 5,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 5.1,
        response_count: 5,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 4.1,
        response_count: 4,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11016',
        percentage_value: 3.1,
        response_count: 3,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 3.1,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 3.1,
        response_count: 3,
        topic: '',
        total_count: 98
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 2,
        response_count: 2,
        special_count: 0,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 2,
        response_count: 2,
        special_count: 2,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Gay',
        nano_id: '11012',
        percentage_value: 2,
        response_count: 2,
        special_count: 2,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 2,
        response_count: 2,
        special_count: 2,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Veterans',
        nano_id: '11212',
        percentage_value: 2,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11216',
        percentage_value: 2,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 2,
        response_count: 2,
        topic: '',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 2,
        response_count: 2,
        topic: '',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Bisexual',
        nano_id: '11010',
        percentage_value: 1,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Lesbian',
        nano_id: '11011',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11116',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Indigenous and First nations',
        nano_id: '11113',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 98
      }
    ],
    '10214_10712': [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 36.7,
        response_count: 36,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 28.6,
        response_count: 28,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 26.5,
        response_count: 26,
        special_count: 0,
        topic: 'gender',
        total_count: 98
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 21.4,
        response_count: 21,
        special_count: 0,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 20.4,
        response_count: 20,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 12.2,
        response_count: 12,
        special_count: 0,
        topic: 'gender',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 12.2,
        response_count: 12,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 7.1,
        response_count: 7,
        special_count: 0,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 7.1,
        response_count: 7,
        special_count: 0,
        topic: 'age',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 6.1,
        response_count: 6,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Veterans',
        nano_id: '11212',
        percentage_value: 6.1,
        response_count: 6,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 4.1,
        response_count: 4,
        special_count: 4,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 4.1,
        response_count: 4,
        special_count: 4,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 4.1,
        response_count: 4,
        topic: '',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 4.1,
        response_count: 4,
        topic: '',
        total_count: 98
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '10916',
        percentage_value: 3.1,
        response_count: 3,
        special_count: 0,
        topic: 'gender',
        total_count: 98
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11016',
        percentage_value: 3.1,
        response_count: 3,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 3.1,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 3.1,
        response_count: 3,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '10815',
        percentage_value: 2,
        response_count: 2,
        special_count: 0,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11117',
        percentage_value: 2,
        response_count: 2,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11216',
        percentage_value: 2,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11015',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Lesbian',
        nano_id: '11011',
        percentage_value: 1,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Queer',
        nano_id: '11013',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Indigenous and First nations',
        nano_id: '11113',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 1,
        response_count: 1,
        topic: '',
        total_count: 98
      }
    ],
    '10214_10713': [
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 6.1,
        response_count: 6,
        special_count: 0,
        topic: 'gender',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 6.1,
        response_count: 6,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 5.1,
        response_count: 5,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 3.1,
        response_count: 3,
        special_count: 3,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 3.1,
        response_count: 3,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 3.1,
        response_count: 3,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 3.1,
        response_count: 3,
        topic: '',
        total_count: 98
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 2,
        response_count: 2,
        special_count: 0,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11116',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 1,
        response_count: 1,
        special_count: 1,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 1,
        response_count: 1,
        topic: '',
        total_count: 98
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 98
      }
    ],
    '10214_10714': [
      {
        display: false,
        group_name: '60 or older',
        nano_id: '10814',
        percentage_value: 1,
        response_count: 1,
        special_count: 1,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Veterans',
        nano_id: '11212',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 1,
        response_count: 1,
        topic: '',
        total_count: 98
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 98
      }
    ],
    '10214_all': [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 71.4,
        response_count: 70,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 60.2,
        response_count: 59,
        special_count: 0,
        topic: 'gender',
        total_count: 98
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 57.1,
        response_count: 56,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 44.9,
        response_count: 44,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 39.8,
        response_count: 39,
        special_count: 0,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 25.5,
        response_count: 25,
        special_count: 0,
        topic: 'age',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 23.5,
        response_count: 23,
        special_count: 0,
        topic: 'gender',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 21.4,
        response_count: 21,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 10.2,
        response_count: 10,
        special_count: 0,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 10.2,
        response_count: 10,
        special_count: 10,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 10.2,
        response_count: 10,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 10.2,
        response_count: 10,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 10.2,
        response_count: 10,
        topic: '',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 10.2,
        response_count: 10,
        topic: '',
        total_count: 98
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 9.2,
        response_count: 9,
        special_count: 9,
        topic: 'age',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Veterans',
        nano_id: '11212',
        percentage_value: 9.2,
        response_count: 9,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 8.2,
        response_count: 8,
        topic: '',
        total_count: 98
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11016',
        percentage_value: 7.1,
        response_count: 7,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 7.1,
        response_count: 7,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 6.1,
        response_count: 6,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Gay',
        nano_id: '11012',
        percentage_value: 5.1,
        response_count: 5,
        special_count: 4,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11216',
        percentage_value: 5.1,
        response_count: 5,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '10916',
        percentage_value: 3.1,
        response_count: 3,
        special_count: 0,
        topic: 'gender',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11116',
        percentage_value: 3.1,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11117',
        percentage_value: 3.1,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '10815',
        percentage_value: 2,
        response_count: 2,
        special_count: 0,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Bisexual',
        nano_id: '11010',
        percentage_value: 2,
        response_count: 2,
        special_count: 2,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Lesbian',
        nano_id: '11011',
        percentage_value: 2,
        response_count: 2,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Queer',
        nano_id: '11013',
        percentage_value: 2,
        response_count: 2,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: true,
        group_name: 'Indigenous and First nations',
        nano_id: '11113',
        percentage_value: 2,
        response_count: 2,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 98
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 2,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 98
      },
      {
        display: false,
        group_name: '60 or older',
        nano_id: '10814',
        percentage_value: 1,
        response_count: 1,
        special_count: 1,
        topic: 'age',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Non-binary',
        nano_id: '10912',
        percentage_value: 1,
        response_count: 1,
        special_count: 1,
        topic: 'gender',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11015',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      },
      {
        display: false,
        group_name: 'Asexual',
        nano_id: '11017',
        percentage_value: 1,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 98
      }
    ],
    '10215_10710': [
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 15.2,
        response_count: 5,
        special_count: 0,
        topic: 'gender',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 15.2,
        response_count: 5,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 15.2,
        response_count: 5,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 15.2,
        response_count: 5,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 9.1,
        response_count: 3,
        special_count: 0,
        topic: 'age',
        total_count: 33
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 9.1,
        response_count: 3,
        special_count: 0,
        topic: 'age',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Bisexual',
        nano_id: '11010',
        percentage_value: 3,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11216',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 3,
        response_count: 1,
        topic: '',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 33
      }
    ],
    '10215_10711': [
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 18.2,
        response_count: 6,
        special_count: 0,
        topic: 'gender',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 15.2,
        response_count: 5,
        special_count: 0,
        topic: 'age',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 12.1,
        response_count: 4,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 12.1,
        response_count: 4,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 12.1,
        response_count: 4,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Queer',
        nano_id: '11013',
        percentage_value: 6.1,
        response_count: 2,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 6.1,
        response_count: 2,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 6.1,
        response_count: 2,
        topic: '',
        total_count: 33
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 33
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Lesbian',
        nano_id: '11011',
        percentage_value: 3,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11016',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11116',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 3,
        response_count: 1,
        special_count: 1,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 3,
        response_count: 1,
        topic: '',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 33
      }
    ],
    '10215_10712': [
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 36.4,
        response_count: 12,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 33.3,
        response_count: 11,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 30.3,
        response_count: 10,
        special_count: 0,
        topic: 'gender',
        total_count: 33
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 30.3,
        response_count: 10,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 27.3,
        response_count: 9,
        special_count: 0,
        topic: 'age',
        total_count: 33
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 9.1,
        response_count: 3,
        special_count: 0,
        topic: 'age',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 9.1,
        response_count: 3,
        special_count: 0,
        topic: 'gender',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 33
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11016',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Gay',
        nano_id: '11012',
        percentage_value: 3,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 3,
        response_count: 1,
        special_count: 1,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 3,
        response_count: 1,
        topic: '',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 3,
        response_count: 1,
        topic: '',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 33
      }
    ],
    '10215_10713': [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 9.1,
        response_count: 3,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 9.1,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 6.1,
        response_count: 2,
        special_count: 0,
        topic: 'age',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 6.1,
        response_count: 2,
        special_count: 0,
        topic: 'gender',
        total_count: 33
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 6.1,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 33
      }
    ],
    '10215_10714': [
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 33
      }
    ],
    '10215_all': [
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 75.8,
        response_count: 25,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 72.7,
        response_count: 24,
        special_count: 0,
        topic: 'gender',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 72.7,
        response_count: 24,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 63.6,
        response_count: 21,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 45.5,
        response_count: 15,
        special_count: 0,
        topic: 'age',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 27.3,
        response_count: 9,
        special_count: 0,
        topic: 'age',
        total_count: 33
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 18.2,
        response_count: 6,
        special_count: 0,
        topic: 'age',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 18.2,
        response_count: 6,
        special_count: 0,
        topic: 'gender',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 12.1,
        response_count: 4,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 12.1,
        response_count: 4,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 12.1,
        response_count: 4,
        topic: '',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Queer',
        nano_id: '11013',
        percentage_value: 6.1,
        response_count: 2,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11016',
        percentage_value: 6.1,
        response_count: 2,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 6.1,
        response_count: 2,
        special_count: 2,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 6.1,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 6.1,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 6.1,
        response_count: 2,
        topic: '',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Lesbian',
        nano_id: '11011',
        percentage_value: 3,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Gay',
        nano_id: '11012',
        percentage_value: 3,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Bisexual',
        nano_id: '11010',
        percentage_value: 3,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 33
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11116',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 33
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11216',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 33
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 33
      }
    ],
    '10216_10712': [
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 75,
        response_count: 3,
        special_count: 0,
        topic: 'age',
        total_count: 4
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 75,
        response_count: 3,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 4
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 75,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 4
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 50,
        response_count: 2,
        special_count: 0,
        topic: 'gender',
        total_count: 4
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 25,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 4
      },
      {
        display: true,
        group_name: 'Veterans',
        nano_id: '11212',
        percentage_value: 25,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 4
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 25,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 4
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 25,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 4
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 4
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 4
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 4
      }
    ],
    '10216_10713': [
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 25,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 4
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 25,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 4
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 25,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 4
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 25,
        response_count: 1,
        special_count: 1,
        topic: 'ethnicity',
        total_count: 4
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 25,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 4
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 25,
        response_count: 1,
        topic: '',
        total_count: 4
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 4
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 4
      }
    ],
    '10216_all': [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 100,
        response_count: 4,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 4
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 75,
        response_count: 3,
        special_count: 0,
        topic: 'age',
        total_count: 4
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 75,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 4
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 50,
        response_count: 2,
        special_count: 0,
        topic: 'gender',
        total_count: 4
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 50,
        response_count: 2,
        special_count: 0,
        topic: 'gender',
        total_count: 4
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 50,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 4
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 25,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 4
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 25,
        response_count: 1,
        special_count: 1,
        topic: 'ethnicity',
        total_count: 4
      },
      {
        display: true,
        group_name: 'Veterans',
        nano_id: '11212',
        percentage_value: 25,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 4
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 25,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 4
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 25,
        response_count: 1,
        topic: '',
        total_count: 4
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 4
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 4
      }
    ],
    '10217_10710': [
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Bisexual',
        nano_id: '11010',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 1,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 7.1,
        response_count: 1,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 7.1,
        response_count: 1,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      }
    ],
    '10217_10711': [
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 14
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      }
    ],
    '10217_10712': [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 57.1,
        response_count: 8,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 35.7,
        response_count: 5,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 35.7,
        response_count: 5,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 28.6,
        response_count: 4,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 14.3,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Indigenous and First nations',
        nano_id: '11113',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 1,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 7.1,
        response_count: 1,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      }
    ],
    '10217_10713': [
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 1,
        topic: 'age',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 14
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 7.1,
        response_count: 1,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      }
    ],
    '10217_10714': [
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 14
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 14
      }
    ],
    '10217_all': [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 78.6,
        response_count: 11,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 42.9,
        response_count: 6,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 42.9,
        response_count: 6,
        special_count: 0,
        topic: 'gender',
        total_count: 14
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 42.9,
        response_count: 6,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 42.9,
        response_count: 6,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 28.6,
        response_count: 4,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 28.6,
        response_count: 4,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'age',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 21.4,
        response_count: 3,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 14.3,
        response_count: 2,
        special_count: 2,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 14.3,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 14.3,
        response_count: 2,
        topic: '',
        total_count: 14
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 1,
        topic: 'age',
        total_count: 14
      },
      {
        display: false,
        group_name: 'Bisexual',
        nano_id: '11010',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Indigenous and First nations',
        nano_id: '11113',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 7.1,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 14
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 7.1,
        response_count: 1,
        topic: '',
        total_count: 14
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 7.1,
        response_count: 1,
        topic: '',
        total_count: 14
      }
    ],
    '10218_10712': [
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 50,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 2
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 50,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 2
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 50,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 2
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 50,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 2
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 50,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 2
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 2
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 2
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 2
      }
    ],
    '10218_10713': [
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 50,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 2
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 50,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 2
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 50,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 2
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 50,
        response_count: 1,
        special_count: 1,
        topic: 'ethnicity',
        total_count: 2
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 50,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 2
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 50,
        response_count: 1,
        topic: '',
        total_count: 2
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 2
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 2
      }
    ],
    '10218_all': [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 100,
        response_count: 2,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 2
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 50,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 2
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 50,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 2
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 50,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 2
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 50,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 2
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 50,
        response_count: 1,
        special_count: 1,
        topic: 'ethnicity',
        total_count: 2
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 50,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 2
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 50,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 2
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 50,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 2
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 50,
        response_count: 1,
        topic: '',
        total_count: 2
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 2
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 2
      }
    ],
    '10219_10711': [
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 15.2,
        response_count: 12,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 12.7,
        response_count: 10,
        special_count: 0,
        topic: 'gender',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 12.7,
        response_count: 10,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 11.4,
        response_count: 9,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 8.9,
        response_count: 7,
        special_count: 0,
        topic: 'age',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 8.9,
        response_count: 7,
        special_count: 0,
        topic: 'age',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 5.1,
        response_count: 4,
        special_count: 0,
        topic: 'gender',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 5.1,
        response_count: 4,
        special_count: 4,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 5.1,
        response_count: 4,
        topic: '',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Bisexual',
        nano_id: '11010',
        percentage_value: 3.8,
        response_count: 3,
        special_count: 3,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 3.8,
        response_count: 3,
        topic: '',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Queer',
        nano_id: '11013',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Pansexual',
        nano_id: '11018',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11015',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 79
      }
    ],
    '10219_10712': [
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 45.6,
        response_count: 36,
        special_count: 0,
        topic: 'gender',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 41.8,
        response_count: 33,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 38,
        response_count: 30,
        special_count: 0,
        topic: 'age',
        total_count: 79
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 38,
        response_count: 30,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 32.9,
        response_count: 26,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 19,
        response_count: 15,
        special_count: 15,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 19,
        response_count: 15,
        topic: '',
        total_count: 79
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11016',
        percentage_value: 15.2,
        response_count: 12,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 10.1,
        response_count: 8,
        special_count: 0,
        topic: 'age',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 10.1,
        response_count: 8,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 8.9,
        response_count: 7,
        special_count: 0,
        topic: 'age',
        total_count: 79
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '10916',
        percentage_value: 8.9,
        response_count: 7,
        special_count: 0,
        topic: 'gender',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 8.9,
        response_count: 7,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 7.6,
        response_count: 6,
        special_count: 0,
        topic: 'gender',
        total_count: 79
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11117',
        percentage_value: 7.6,
        response_count: 6,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11216',
        percentage_value: 7.6,
        response_count: 6,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 7.6,
        response_count: 6,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 7.6,
        response_count: 6,
        topic: '',
        total_count: 79
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '10815',
        percentage_value: 5.1,
        response_count: 4,
        special_count: 0,
        topic: 'age',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11116',
        percentage_value: 3.8,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 2,
        topic: 'age',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Non-binary',
        nano_id: '10912',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 2,
        topic: 'gender',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Gay',
        nano_id: '11012',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 2,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Bisexual',
        nano_id: '11010',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Veterans',
        nano_id: '11212',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 2.5,
        response_count: 2,
        topic: '',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Pansexual',
        nano_id: '11018',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11015',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Indigenous and First nations',
        nano_id: '11113',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Native Hawaiian or Other Pacific Islander',
        nano_id: '11114',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      }
    ],
    '10219_10713': [
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 6.3,
        response_count: 5,
        special_count: 0,
        topic: 'age',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 6.3,
        response_count: 5,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 6.3,
        response_count: 5,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 3.8,
        response_count: 3,
        special_count: 0,
        topic: 'gender',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 3.8,
        response_count: 3,
        special_count: 0,
        topic: 'gender',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 3.8,
        response_count: 3,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11116',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Lesbian',
        nano_id: '11011',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 1,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 1.3,
        response_count: 1,
        topic: '',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 1.3,
        response_count: 1,
        topic: '',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 79
      }
    ],
    '10219_all': [
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 62,
        response_count: 49,
        special_count: 0,
        topic: 'gender',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 60.8,
        response_count: 48,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 55.7,
        response_count: 44,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 53.2,
        response_count: 42,
        special_count: 0,
        topic: 'age',
        total_count: 79
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 50.6,
        response_count: 40,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 25.3,
        response_count: 20,
        special_count: 20,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 25.3,
        response_count: 20,
        topic: '',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 19,
        response_count: 15,
        special_count: 0,
        topic: 'age',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 16.5,
        response_count: 13,
        special_count: 0,
        topic: 'gender',
        total_count: 79
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11016',
        percentage_value: 15.2,
        response_count: 12,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 13.9,
        response_count: 11,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 12.7,
        response_count: 10,
        topic: '',
        total_count: 79
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 10.1,
        response_count: 8,
        special_count: 0,
        topic: 'age',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 10.1,
        response_count: 8,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '10916',
        percentage_value: 8.9,
        response_count: 7,
        special_count: 0,
        topic: 'gender',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 8.9,
        response_count: 7,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11117',
        percentage_value: 7.6,
        response_count: 6,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11216',
        percentage_value: 7.6,
        response_count: 6,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Bisexual',
        nano_id: '11010',
        percentage_value: 6.3,
        response_count: 5,
        special_count: 4,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11116',
        percentage_value: 6.3,
        response_count: 5,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '10815',
        percentage_value: 5.1,
        response_count: 4,
        special_count: 0,
        topic: 'age',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 3.8,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 2,
        topic: 'age',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Non-binary',
        nano_id: '10912',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 2,
        topic: 'gender',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Gay',
        nano_id: '11012',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 2,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Pansexual',
        nano_id: '11018',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11015',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Veterans',
        nano_id: '11212',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 2.5,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 2.5,
        response_count: 2,
        topic: '',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Lesbian',
        nano_id: '11011',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Queer',
        nano_id: '11013',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 79
      },
      {
        display: true,
        group_name: 'Indigenous and First nations',
        nano_id: '11113',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      },
      {
        display: false,
        group_name: 'Native Hawaiian or Other Pacific Islander',
        nano_id: '11114',
        percentage_value: 1.3,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 79
      }
    ],
    '10220_10710': [
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 5
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 5
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 5
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 5
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 5
      }
    ],
    '10220_10711': [
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 40,
        response_count: 2,
        special_count: 0,
        topic: 'gender',
        total_count: 5
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 40,
        response_count: 2,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 5
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 20,
        response_count: 1,
        special_count: 1,
        topic: 'age',
        total_count: 5
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 5
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Veterans',
        nano_id: '11212',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 5
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 20,
        response_count: 1,
        topic: '',
        total_count: 5
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 5
      }
    ],
    '10220_10712': [
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 5
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 5
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 20,
        response_count: 1,
        special_count: 1,
        topic: 'ethnicity',
        total_count: 5
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 20,
        response_count: 1,
        topic: '',
        total_count: 5
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 5
      }
    ],
    '10220_10713': [
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 5
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 5
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 5
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 20,
        response_count: 1,
        special_count: 1,
        topic: 'ethnicity',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 20,
        response_count: 1,
        topic: '',
        total_count: 5
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 5
      }
    ],
    '10220_10714': [
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 5
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 5
      },
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 5
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 5
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 5
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 5
      }
    ],
    '10220_all': [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 120,
        response_count: 6,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 5
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 100,
        response_count: 5,
        special_count: 0,
        topic: 'gender',
        total_count: 5
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 60,
        response_count: 3,
        special_count: 0,
        topic: 'age',
        total_count: 5
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 60,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 5
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 40,
        response_count: 2,
        special_count: 2,
        topic: 'ethnicity',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 40,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 5
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 40,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 40,
        response_count: 2,
        topic: '',
        total_count: 5
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 20,
        response_count: 1,
        special_count: 1,
        topic: 'age',
        total_count: 5
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 5
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'gender',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Veterans',
        nano_id: '11212',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 5
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 20,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 5
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 20,
        response_count: 1,
        topic: '',
        total_count: 5
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 5
      }
    ],
    all_10710: [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 6.1,
        response_count: 17,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 6.1,
        response_count: 17,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 5.4,
        response_count: 15,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 5,
        response_count: 14,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 4.3,
        response_count: 12,
        special_count: 0,
        topic: 'gender',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 4,
        response_count: 11,
        special_count: 0,
        topic: 'gender',
        total_count: 278
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 3.2,
        response_count: 9,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 2.2,
        response_count: 6,
        topic: '',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 1.4,
        response_count: 4,
        special_count: 4,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 1.4,
        response_count: 4,
        topic: '',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Bisexual',
        nano_id: '11010',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 3,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Gay',
        nano_id: '11012',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 2,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 0.7,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11216',
        percentage_value: 0.7,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Non-binary',
        nano_id: '10912',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 1,
        topic: 'gender',
        total_count: 278
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11016',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Queer',
        nano_id: '11013',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Asexual',
        nano_id: '11017',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11116',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11117',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 278
      }
    ],
    all_10711: [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 17.3,
        response_count: 48,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 15.1,
        response_count: 42,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 13.7,
        response_count: 38,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 13.3,
        response_count: 37,
        special_count: 0,
        topic: 'gender',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 12.6,
        response_count: 35,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 9,
        response_count: 25,
        special_count: 0,
        topic: 'gender',
        total_count: 278
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 7.2,
        response_count: 20,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 4.3,
        response_count: 12,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 4,
        response_count: 11,
        special_count: 11,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 4,
        response_count: 11,
        topic: '',
        total_count: 278
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 3.2,
        response_count: 9,
        topic: '',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 2.2,
        response_count: 6,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 2.2,
        response_count: 6,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 1.8,
        response_count: 5,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Queer',
        nano_id: '11013',
        percentage_value: 1.4,
        response_count: 4,
        special_count: 2,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Bisexual',
        nano_id: '11010',
        percentage_value: 1.4,
        response_count: 4,
        special_count: 4,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11016',
        percentage_value: 1.4,
        response_count: 4,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 1.4,
        response_count: 4,
        topic: '',
        total_count: 278
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 3,
        topic: 'age',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Veterans',
        nano_id: '11212',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11216',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Lesbian',
        nano_id: '11011',
        percentage_value: 0.7,
        response_count: 2,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Gay',
        nano_id: '11012',
        percentage_value: 0.7,
        response_count: 2,
        special_count: 2,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11116',
        percentage_value: 0.7,
        response_count: 2,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 0.7,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 0.7,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: '60 or older',
        nano_id: '10814',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 1,
        topic: 'age',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Pansexual',
        nano_id: '11018',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11015',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Indigenous and First nations',
        nano_id: '11113',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      }
    ],
    all_10712: [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 40.6,
        response_count: 113,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 32.4,
        response_count: 90,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 30.6,
        response_count: 85,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 29.9,
        response_count: 83,
        special_count: 0,
        topic: 'gender',
        total_count: 278
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 27,
        response_count: 75,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 15.8,
        response_count: 44,
        special_count: 0,
        topic: 'gender',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 10.8,
        response_count: 30,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 9,
        response_count: 25,
        special_count: 25,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 9,
        response_count: 25,
        topic: '',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 7.6,
        response_count: 21,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 7.6,
        response_count: 21,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11016',
        percentage_value: 5.8,
        response_count: 16,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 4.7,
        response_count: 13,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 4.3,
        response_count: 12,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 4,
        response_count: 11,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 4,
        response_count: 11,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '10916',
        percentage_value: 3.6,
        response_count: 10,
        special_count: 0,
        topic: 'gender',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Veterans',
        nano_id: '11212',
        percentage_value: 3.2,
        response_count: 9,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 3.2,
        response_count: 9,
        topic: '',
        total_count: 278
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11117',
        percentage_value: 2.9,
        response_count: 8,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11216',
        percentage_value: 2.9,
        response_count: 8,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 2.2,
        response_count: 6,
        special_count: 6,
        topic: 'age',
        total_count: 278
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '10815',
        percentage_value: 2.2,
        response_count: 6,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 2.2,
        response_count: 6,
        topic: '',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Gay',
        nano_id: '11012',
        percentage_value: 1.4,
        response_count: 4,
        special_count: 4,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 1.4,
        response_count: 4,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Indigenous and First nations',
        nano_id: '11113',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11116',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Non-binary',
        nano_id: '10912',
        percentage_value: 0.7,
        response_count: 2,
        special_count: 2,
        topic: 'gender',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11015',
        percentage_value: 0.7,
        response_count: 2,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Bisexual',
        nano_id: '11010',
        percentage_value: 0.7,
        response_count: 2,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Lesbian',
        nano_id: '11011',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Queer',
        nano_id: '11013',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Pansexual',
        nano_id: '11018',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Native Hawaiian or Other Pacific Islander',
        nano_id: '11114',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      }
    ],
    all_10713: [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 9,
        response_count: 25,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 6.8,
        response_count: 19,
        special_count: 0,
        topic: 'gender',
        total_count: 278
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 6.5,
        response_count: 18,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 5,
        response_count: 14,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 3.6,
        response_count: 10,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 3.6,
        response_count: 10,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 3.6,
        response_count: 10,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 2.9,
        response_count: 8,
        special_count: 0,
        topic: 'gender',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 2.5,
        response_count: 7,
        topic: '',
        total_count: 278
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 2.2,
        response_count: 6,
        special_count: 6,
        topic: 'age',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 2.2,
        response_count: 6,
        special_count: 6,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 2.2,
        response_count: 6,
        topic: '',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11116',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 0.7,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: '60 or older',
        nano_id: '10814',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 1,
        topic: 'age',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Lesbian',
        nano_id: '11011',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11016',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0.4,
        response_count: 1,
        topic: '',
        total_count: 278
      }
    ],
    all_10714: [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 2.2,
        response_count: 6,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 1.8,
        response_count: 5,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 1.4,
        response_count: 4,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 1.4,
        response_count: 4,
        special_count: 0,
        topic: 'gender',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 0.7,
        response_count: 2,
        special_count: 0,
        topic: 'gender',
        total_count: 278
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 0.7,
        response_count: 2,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 0.7,
        response_count: 2,
        topic: '',
        total_count: 278
      },
      {
        display: false,
        group_name: '60 or older',
        nano_id: '10814',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 1,
        topic: 'age',
        total_count: 278
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 1,
        topic: 'age',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Veterans',
        nano_id: '11212',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 0,
        response_count: 0,
        topic: '',
        total_count: 278
      }
    ],
    all_all: [
      {
        display: false,
        group_name: 'Heterosexual / straight',
        nano_id: '11014',
        percentage_value: 75.2,
        response_count: 209,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'White',
        nano_id: '11115',
        percentage_value: 59.7,
        response_count: 166,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Man',
        nano_id: '10910',
        percentage_value: 55.4,
        response_count: 154,
        special_count: 0,
        topic: 'gender',
        total_count: 278
      },
      {
        display: false,
        group_name: 'None of the above',
        nano_id: '11215',
        percentage_value: 52.5,
        response_count: 146,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: '30-39',
        nano_id: '10811',
        percentage_value: 44.6,
        response_count: 124,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Women',
        nano_id: '10911',
        percentage_value: 32.7,
        response_count: 91,
        special_count: 0,
        topic: 'gender',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Under 30',
        nano_id: '10810',
        percentage_value: 25.2,
        response_count: 70,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Working Parents',
        nano_id: '11213',
        percentage_value: 19.8,
        response_count: 55,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Asian or Asian American',
        nano_id: '11110',
        percentage_value: 16.5,
        response_count: 46,
        special_count: 46,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Asian \u0026 Pacific Islander',
        nano_id: '',
        percentage_value: 16.5,
        response_count: 46,
        topic: '',
        total_count: 278
      },
      {
        display: false,
        group_name: '40-49',
        nano_id: '10812',
        percentage_value: 14,
        response_count: 39,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Black community',
        nano_id: '11111',
        percentage_value: 9.7,
        response_count: 27,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'LGBTQ+ Community',
        nano_id: '',
        percentage_value: 9,
        response_count: 25,
        topic: '',
        total_count: 278
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11016',
        percentage_value: 7.9,
        response_count: 22,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Neurodiverse Community',
        nano_id: '11211',
        percentage_value: 7.9,
        response_count: 22,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Hispanic \u0026 Latinx Community ',
        nano_id: '11112',
        percentage_value: 7.6,
        response_count: 21,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Older \u0026 Experienced Workers',
        nano_id: '',
        percentage_value: 6.8,
        response_count: 19,
        topic: '',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Refugees \u0026 Immigrants',
        nano_id: '11214',
        percentage_value: 6.1,
        response_count: 17,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: '50-59',
        nano_id: '10813',
        percentage_value: 5.8,
        response_count: 16,
        special_count: 16,
        topic: 'age',
        total_count: 278
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11216',
        percentage_value: 4.7,
        response_count: 13,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Veterans',
        nano_id: '11212',
        percentage_value: 4.7,
        response_count: 13,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '10916',
        percentage_value: 3.6,
        response_count: 10,
        special_count: 0,
        topic: 'gender',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Gay',
        nano_id: '11012',
        percentage_value: 3.2,
        response_count: 9,
        special_count: 8,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Bisexual',
        nano_id: '11010',
        percentage_value: 3.2,
        response_count: 9,
        special_count: 8,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11116',
        percentage_value: 3.2,
        response_count: 9,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '11117',
        percentage_value: 3.2,
        response_count: 9,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: true,
        group_name: 'People with disabilities',
        nano_id: '11210',
        percentage_value: 2.9,
        response_count: 8,
        special_count: 0,
        topic: 'community',
        total_count: 278
      },
      {
        display: false,
        group_name: 'I prefer not to answer',
        nano_id: '10815',
        percentage_value: 2.2,
        response_count: 6,
        special_count: 0,
        topic: 'age',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Queer',
        nano_id: '11013',
        percentage_value: 2.2,
        response_count: 6,
        special_count: 2,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Lesbian',
        nano_id: '11011',
        percentage_value: 1.4,
        response_count: 4,
        special_count: 3,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: true,
        group_name: 'Indigenous and First nations',
        nano_id: '11113',
        percentage_value: 1.4,
        response_count: 4,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      },
      {
        display: false,
        group_name: '60 or older',
        nano_id: '10814',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 3,
        topic: 'age',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Non-binary',
        nano_id: '10912',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 3,
        topic: 'gender',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Other',
        nano_id: '11015',
        percentage_value: 1.1,
        response_count: 3,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Pansexual',
        nano_id: '11018',
        percentage_value: 0.7,
        response_count: 2,
        special_count: 1,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Asexual',
        nano_id: '11017',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'sexual_orientation',
        total_count: 278
      },
      {
        display: false,
        group_name: 'Native Hawaiian or Other Pacific Islander',
        nano_id: '11114',
        percentage_value: 0.4,
        response_count: 1,
        special_count: 0,
        topic: 'ethnicity',
        total_count: 278
      }
    ]
  }
}

export const DEMO_DEFINING_TRACKING_DATA = [
  {
    display_text:
      "of employees are familiar with your organization's diversity, equity, and inclusion goals. ",
    rank: 6,
    score: 87
  },
  {
    display_text:
      "of employees feel they know about your organization's employee diversity data reports.",
    rank: 2,
    score: 51
  },
  {
    display_text:
      'of employees feel accountable for helping your organization achieve your DEI goals.',
    rank: 5,
    score: 25
  }
]

export const DEMO_INCLUSION_BELONGING_DATA = {
  organization_avg: 4.1,
  top_opportunity: {
    display_text: 'I will be given fair consideration for career advancement',
    rank: 1,
    score: 3.8
  },
  top_strength: {
    display_text: 'My manager genuinely cares about my well-being',
    rank: 10,
    score: 4.5
  }
}

export const DEMO_ATTRACTING_SOURCING_DATA = {
  top_opportunity: {
    display_text:
      "of employees feel people like them are represented on your organization's website and social media.",
    rank: 8,
    score: 19
  },
  top_strength: {
    display_text:
      'of employees include salary ranges when publishing job postings at least some of the time.',
    rank: 7,
    score: 66
  }
}

export const DEMO_INTERVIEWING_ENGAGING_DATA = {
  top_opportunity: {
    display_text:
      'of employees follow a structured interview process when interviewing candidates at least some of the time.',
    rank: 5,
    score: 22
  },
  top_strength: {
    display_text:
      'of employees submit interview feedback before reviewing the feedback of others, at least some of the time. ',
    rank: 4,
    score: 54
  }
}

export const DEMO_ONBOARDING_ADVANCING_DATA = [
  {
    display_text:
      "of employees felt they've been recognized for contributions at work, either formally or informally, within the last 3 months. ",
    rank: 8,
    score: 76
  },
  {
    display_text:
      'of employees received bias or microaggression training within the last year.',
    rank: 2,
    score: 52
  },
  {
    display_text:
      'of employees were provided a personalized onboarding plan as a new hire at the organization.',
    rank: 6,
    score: 18
  }
]

export const CONSTELLATION_SURVEY_COUNT = {
  completed_percentage: 84.3,
  responded_percentage: null,
  total_sent: null,
  responded_count: 172,
  completed_count: 145
}

export const CONSTELLATION_RESPONSE_RATE_DATA = []

export const CONSTELLATION_DEFINING_TRACKING_DATA = [
  {
    display_text:
      "are familiar with your organization's diversity, equity, and inclusion statement",
    rank: 6,
    score: 79
  }
]

export const CONSTELLATION_INCLUSION_BELONGING_DATA = {
  organization_avg: 4.1,
  top_opportunity: {
    display_text: 'I will be given fair consideration for career advancement',
    rank: 1,
    score: 3.8
  },
  top_strength: {
    display_text: 'My manager genuinely cares about my well-being',
    rank: 10,
    score: 4.5
  }
}

export const CONSTELLATION_ATTRACTING_SOURCING_DATA = {
  top_opportunity: {
    display_text: 'of employees are familiar with internal-facing job postings',
    rank: 7,
    score: 67
  },
  top_strength: {
    display_text:
      "of employees feel people like they are represented on your organization's website and social media",
    rank: 8,
    score: 73
  }
}

export const CONSTELLATION_ONBOARDING_ADVANCING_DATA = [
  {
    display_text:
      'of employees have participated in training or career development program to learn a new skill or capability to get better at their job within the last year',
    rank: 7,
    score: 72
  },
  {
    display_text:
      'of employees have participated in diversity programming at your organization within the last year',
    rank: 5,
    score: 63
  },
  {
    display_text:
      "of employees felt that they've been recognized for contributions at work, either formally or informally, within the last 3 months",
    rank: 8,
    score: 39
  }
]

//@ts-ignore
import * as d3 from 'd3'

import { DepartmentSummaryData } from 'model'

export const createSvg = (svgRef: any, width: number, height: number) => {
  return (
    d3
      //@ts-ignore
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
  )
}

export const createHorizontalBackgroundLines = (
  svg: any,
  numberOfLines: number,
  distanceBetweenLines: number,
  height: number,
  width: number
) => {
  for (let i = 0; i < numberOfLines; i++) {
    const distanceFromBottom = i * distanceBetweenLines
    svg
      .append('path')
      .style('stroke', 'lightgrey')
      .attr(
        'd',
        `
            M0,${height - distanceFromBottom}
              h${width}
            `
      )
  }
}

export const createVerticalBackgroundLines = (
  svg: any,
  numberOfLines: number,
  distanceBetweenLines: number,
  height: number,
  width: number,
  moveLeft?: number
) => {
  for (let i = 0; i < numberOfLines; i++) {
    const distanceFromLeft = i * distanceBetweenLines
    svg
      .append('path')
      .style('stroke', 'lightgrey')
      .attr(
        'd',
        `
            M${width - distanceFromLeft},0
              v${height}
            `
      )
  }
}

export const createVerticalBars = (
  svg: any,
  data: DepartmentSummaryData[],
  moveOnXAxis: number,
  graphHeight: number,
  distanceBetweenBars: number,
  barWidth: number,
  color: string,
  transitionDuration: number
) => {
  let xPlacement = moveOnXAxis
  let initialXPlacement = moveOnXAxis

  svg
    .selectAll()
    .data(data)
    .enter()
    .append('path')
    .attr(
      'd',
      (item: any) => `
              M${(initialXPlacement += distanceBetweenBars)},${graphHeight}
              v${0}
              a 13 13 0 0 1 12 -12
              h${barWidth}
              a 13 13 0 0 1 12 12
              v${0}
              `
    )
    .style('fill', color)
    .transition()
    .duration(transitionDuration)
    .attr(
      'd',
      (item: any) => `
            M${(xPlacement += distanceBetweenBars)},${graphHeight}
            v${-item.percentage_value * 4 + 11}
            a 13 13 0 0 1 12 -12
            h${barWidth}
            a 13 13 0 0 1 12 12
            v${item.percentage_value * 4 - 11}
            `
    )
}

export const createHorizontalBars = (
  svg: any,
  data: any,
  moveOnYAxis: number,
  moveOnXAxis: number,
  distanceBetweenBars: number,
  barWidth: number,
  transitionDuration: number,
  graphWidth: number,
  isResponseRateGraph: boolean,
  color?: string,
  gradientColor1?: string,
  gradientColor2?: string
) => {
  let initialPlacement = moveOnYAxis
  let yPlacement = moveOnYAxis

  const gradient = svg
    .append('defs')
    .append('linearGradient')
    .attr('id', 'gradient')
    .attr('x1', '0%')
    .attr('x2', '100%')
    .attr('y1', '0%')
    .attr('y2', '0%')
  gradient
    .append('stop')
    .attr('offset', '0%')
    .style('stop-color', gradientColor1)
    .style('stop-opacity', 1)

  gradient
    .append('stop')
    .attr('offset', '100%')
    .style('stop-color', gradientColor2)
    .style('stop-opacity', 1)

  svg
    .selectAll()
    .data(data)
    .enter()
    .append('path')
    .style('fill', 'url(#gradient)')
    .attr(
      'd',
      () => `
        M${moveOnXAxis},${(initialPlacement += distanceBetweenBars)}
        h${0}
        a ${findArcValue(true, isResponseRateGraph)}
        v${barWidth}
        a ${findArcValue(false, isResponseRateGraph)}
        h${0}
        `
    )
    .transition()
    .duration(transitionDuration)
    .attr('d', (item: any) =>
      item.percentage_value === 0 || item.score === 0
        ? `
        M${moveOnXAxis},${(initialPlacement += distanceBetweenBars)}
        `
        : `
      M${moveOnXAxis},${(yPlacement += distanceBetweenBars)}
      h${findHValue(true, item, isResponseRateGraph, graphWidth)}
      a ${findArcValue(true, isResponseRateGraph)}
      v${barWidth}
      a ${findArcValue(false, isResponseRateGraph)}
      h${findHValue(false, item, isResponseRateGraph, graphWidth)}
      `
    )
}

const findHValue = (
  first: boolean,
  item: any,
  isResponseRateGraph: boolean,
  graphWidth: number
) => {
  if (first) {
    return isResponseRateGraph
      ? item.percentage_value * (graphWidth / 100) - 6
      : item.score * 7.23 - 12
  }
  return isResponseRateGraph
    ? -item.percentage_value * (graphWidth / 100) + 6
    : -item.score * 7.23 + 12
}

const findArcValue = (first: boolean, isResponseRateGraph: boolean) => {
  if (first) {
    return isResponseRateGraph ? '6 6 0 0 1 6 6' : '12 12 0 0 1 12 12'
  }
  return isResponseRateGraph ? '6 6 0 0 1 -6 6' : '12 12 0 0 1 -12 12'
}

export const addLabelsToBars = (
  svg: any,
  data: DepartmentSummaryData[],
  yScale: Function,
  distanceBetweenLabels: number,
  moveOnXAxis: number,
  color: string,
  fontFamily: string
) => {
  svg
    .selectAll()
    .data(data)
    .enter()
    .append('text')
    .text((item: any) => `${item.percentage_value}%`)
    .attr(
      'x',
      (item: any, index: any) => index * distanceBetweenLabels + moveOnXAxis
    )
    .attr('y', (item: any) => yScale(item.percentage_value / 2))
    .style('stroke', color)
    .style('fill', color)
    .style('font-family', fontFamily)
}

export const createVerticalDottedLine = (
  svg: any,
  height: number,
  width: number,
  average: number,
  color: string,
  transitionDuration: number
) => {
  svg
    .append('path')
    .style('stroke', color)
    .style('stroke-dasharray', '10 4')
    .style('stroke-width', 4)
    .attr(
      'd',
      `
          M250,0
            v${height}
          `
    )
    .transition()
    .duration(transitionDuration)
    .attr(
      'd',
      `
          M${(width / 100) * average + 250},0
            v${height}
          `
    )
}

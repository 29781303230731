import React, { useState } from 'react'
import { Box, Alert, IconButton, Collapse, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { UserAlertType } from 'model'
import { Color } from '@mathison-inc/components'

// Local storage functions
export const setLocalStore = (
  key: string,
  hours?: number | undefined
): void => {
  const timeToExpire =
    hours && hours > 0 ? hours * 60 * 60 * 1000 : 24 * 60 * 60 * 1000
  const userStore = {
    value: false,
    expiry: new Date().getTime() + timeToExpire
  }
  localStorage.setItem(key, JSON.stringify(userStore))
}

export const getLocalStore = (key: string): boolean => {
  const userStore = localStorage.getItem(key)
  if (!userStore) {
    return true
  }
  const item = JSON.parse(userStore)
  const now = new Date().getTime()
  if (now > item.expiry) {
    localStorage.removeItem(key)
    return true
  }
  return item.value
}

interface IProps {
  userAlert: UserAlertType
  hoursToHide: number | undefined
}

const UserAlert = ({ userAlert, hoursToHide }: IProps) => {
  const alertIsOpen: boolean = getLocalStore('surveyAlert')
  const [open, setOpen] = useState<boolean>(alertIsOpen)

  const handleClose = () => {
    setOpen(false)
    setLocalStore('surveyAlert', hoursToHide)
  }

  return (
    <Box sx={{ width: '100%', marginBottom: '40px' }}>
      <Collapse in={open}>
        <Alert
          severity='warning'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                handleClose()
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
          sx={{
            mb: 2,
            backgroundColor: '#F0E8FF',
            padding: '20px 30px',
            borderRadius: '8px',
            color: Color.main,
            '.MuiAlert-icon': {
              color: '#420CA4',
              marginTop: '5px'
            }
          }}
        >
          <Typography
            variant='h6'
            sx={{ fontSize: '20px', lineHeight: '28px' }}
          >
            {userAlert.title}
          </Typography>
          <Typography
            variant='body1'
            sx={{ fontSize: '18px', lineHeight: '24px' }}
          >
            {userAlert.message}
          </Typography>
          <Typography
            variant='body1'
            sx={{ fontSize: '18px', lineHeight: '24px' }}
          >
            Please contact us at{' '}
            <a
              style={{
                fontSize: '18px',
                lineHeight: '24px',
                display: 'inline-block',
                textDecoration: 'underline'
              }}
              href='mailto:support@mathison.io'
            >
              support@mathison.io
            </a>{' '}
            if you have any questions.
          </Typography>
        </Alert>
      </Collapse>
    </Box>
  )
}
export default UserAlert

import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Grid } from '@mui/material'
import EmployeeSurveyRouter from 'pages/EmployeeSurvey/router'
import { SurveySummaryProvider } from 'context/SurveySummary'
import EquitableBehavior from 'pages/EquitableBehaviors'

const RouteProvider = () => {
  return (
    <Grid
      sx={{
        margin: '40px auto',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '1460px',
        width: '90%'
      }}
    >
      <Grid sx={{ width: '100%' }}>
        <Routes>
          <Route
            path='/equity-index/employee-survey/*'
            element={
              <SurveySummaryProvider>
                <EmployeeSurveyRouter />
              </SurveySummaryProvider>
            }
          />
          <Route
            path='/equity-index/employee-survey/equitable-behaviors/:tab?'
            element={<EquitableBehavior />}
          />
        </Routes>
      </Grid>
    </Grid>
  )
}
export default RouteProvider

import React, { useContext } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import { SurveySummaryContext } from 'context/SurveySummary'
import EbButton from 'components/EbButton'
import EbCard from './EbCard'

const EquitableBehaviorsSection = () => {
  const { equitableData, showDigDeeper } = useContext(SurveySummaryContext)

  if (!equitableData) return <></>
  return (
    <>
      <Typography
        variant='h4'
        sx={{
          marginTop: '8px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        Equitable Behaviors
      </Typography>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {Object.keys(equitableData).map(key => (
          <Box key={key}>
            {key && equitableData[key] !== null && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: '24px'
                  }}
                >
                  <Typography variant='h5' sx={{ width: '100%' }}>
                    {key === 'attracting_sourcing'
                      ? 'Attracting and Sourcing'
                      : 'Interviewing and Engaging'}
                  </Typography>
                  <EbButton
                    showButton={showDigDeeper}
                    url={
                      key === 'attracting_sourcing'
                        ? '/equity-index/employee-survey/equitable-behaviors/attracting-sourcing'
                        : '/equity-index/employee-survey/equitable-behaviors/interviewing-engaging'
                    }
                  >
                    Dig deeper
                  </EbButton>
                </Box>
                <Box>
                  <Typography
                    variant='body1'
                    sx={{
                      padding: '0 0 16px 0',
                      fontSize: '14px',
                      fontWeight: 600,
                      margin: 0,
                      width: '100%'
                    }}
                  >
                    Percentage of respondents agree or strongly agree
                  </Typography>
                </Box>
              </>
            )}

            {equitableData && equitableData[key] && (
              <EbCard
                // @ts-ignore
                strength={equitableData[key].top_strength}
                // @ts-ignore
                opportunity={equitableData[key].top_opportunity}
              />
            )}
          </Box>
        ))}
      </Grid>
    </>
  )
}

export default EquitableBehaviorsSection

import React from 'react'
import { Divider, Grid, Tooltip, Typography } from '@mui/material'
import { MyTab } from 'pages/EquitableBehaviors/constants'

interface IProps {
  percent: any
  denominator: any
  questionText: string[]
  topStrengthTotal: number
  topOppTotal: number
  selectedMainTab: MyTab
}

const TopOpportunity = ({
  questionText,
  percent,
  denominator,
  topStrengthTotal,
  topOppTotal,
  selectedMainTab
}: IProps) => {
  const longText = `${percent} of ${denominator} employees responded`
  return (
    <Grid
      container
      spacing={2}
      alignItems='center'
      sx={{ borderLeft: '1px solid rgba(0, 0, 0, 0.12)', marginLeft: 0 }}
    >
      <Grid item>
        <Tooltip
          title={longText}
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip.css-gmjguz-MuiTooltip-tooltip': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#050B0E',
                borderRadius: '8px',
                fontSize: '14px',
                fontWeight: 700,
                marginLeft: '30%',
                width: '259px',
                height: '68px'
              }
            }
          }}
          placement='bottom'
        >
          <div
            style={{
              height: '80px',
              width: '80px',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: selectedMainTab?.backgroundColor,
              borderRadius: '90%',
              marginLeft: '20px'
            }}
          >
            <Typography
              sx={{
                textAlign: 'center',
                marginTop: '0.8em',
                color: selectedMainTab.color,
                fontSize: '26px',
                fontWeight: 700
              }}
            >
              {percent}
            </Typography>
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs sx={{ minHeight: '120px', paddingRight: '20px' }}>
        <Grid container direction='column' spacing={1}>
          <Grid item>
            {topStrengthTotal < 2 && topOppTotal < 2 && (
              <Typography
                sx={{
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  fontSize: '12px !important',
                  letterSpacing: '0.17em',
                  lineHeight: 0,
                  marginTop: '16px'
                }}
              >
                {topOppTotal > 0 ? 'Top Opportunity' : 'Top Strength'}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontSize: '14px',
                marginTop: '16px',
                width: '100%'
              }}
            >
              {questionText}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TopOpportunity
